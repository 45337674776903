import {gql, useMutation, useQuery} from '@apollo/client';
import * as React from 'react';
import {Outlet} from "react-router-dom";
import Header from "../components/Header";
import ViewRecord from "../components/ViewRecord";
import {UPDATE_POST} from "../query_and_mutations/update_record";
import "./list.css";


//GRAPHQL QUERY
const GET_LOCATIONS = gql`
query sampleFindAll($page: Float!, $sortDirection: String){
  incident_findAllByFilterWithPagination(filters: {
    fromDate: "2024-01-01 00:00:00",
    toDate: "2026-12-31 23:59:59",
    isVerified: false
  }, options: {
    paginationOption: {
      page: $page
      resultsPerPage: 30
    }, sortOptions: {
        by: "createdAt",
        direction: $sortDirection
      }
  }) {
    paginationDetails {
      page
      resultsPerPage
      totalCount
      hasPreviousPage
      hasNextPage
      previousPage
      nextPage
      numberOfPages
    }
    entities {
      id
      isVerified
      incidentDetails {
        id
        location {
          latitude
          longitude
          address
        }
        collisionType
        description
        encoderEmail
        light
        locationApproximate
        mainCause
        reportingAgency
        severities
        weather
        fromDateTime
        toDateTime
      }
      parties {
        id
        adults {
          id
          firstName
          middleName
          lastName
          gender
          involvement
          address
          licenseNumber
          age
          driverError
          alchoholSuspicion
          drugsSuspicion
          seatbeltState
          hospital
        }
        minors {
          id
          firstName
          middleName
          lastName
          gender
          gradeLevel
          involvement
          address
          licenseNumber
          age
          driverError
          alchoholSuspicion
          drugsSuspicion
          seatbeltState
          hospital
        }
        vehicle {
          id
          classification
          vehicleType
          make
          plateNumber
          model
          maneuver
          damages
          defects
          loading
          insuranceDetails
          engineNumber
          chassisNumber
        }
        photos {
          id
          url
        }
      }
      notes
    }
    paginationDetails {
      page
      resultsPerPage
    }
  }
}
  `;


function Stph() {


  const [dialogIsOpen1, setDialogIsOpen1] = React.useState(false)
  const openDialog1 = () => setDialogIsOpen1(true)

  const [page, setPage] = React.useState(1);
  const pageSize = 20; // Number of items per page

  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const [sortDirection, setSortDirection] = React.useState('DESC');

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const closeDialog1 = () => {
    setDialogIsOpen1(false)


    sessionStorage.removeItem("adult_first_name");
    sessionStorage.removeItem("adult_middle_name");
    sessionStorage.removeItem("adult_last_name");
    sessionStorage.removeItem("adult_gender_name");
    sessionStorage.removeItem("adult_involvement");
    sessionStorage.removeItem("adult_address");
    sessionStorage.removeItem("adult_license_number");
    sessionStorage.removeItem("adult_age");
    sessionStorage.removeItem("adult_driverError");
    sessionStorage.removeItem("adult_alchoholSuspicion");
    sessionStorage.removeItem("adult_drugsSuspicion");
    sessionStorage.removeItem("adult_seatbeltState");
    sessionStorage.removeItem("adult_hospital");

    sessionStorage.removeItem("minor_first_name");
    sessionStorage.removeItem("minor_middle_name");
    sessionStorage.removeItem("minor_last_name");
    sessionStorage.removeItem("minor_gender_name");
    sessionStorage.removeItem("minor_grade_level");
    sessionStorage.removeItem("minor_involvement");
    sessionStorage.removeItem("minor_address");
    sessionStorage.removeItem("minor_license_number");
    sessionStorage.removeItem("minor_age");
    sessionStorage.removeItem("minor_age");
    sessionStorage.removeItem("minor_alchoholSuspicion");
    sessionStorage.removeItem("minor_drugsSuspicion");
    sessionStorage.removeItem("minor_seatbeltState");
    sessionStorage.removeItem("minor_hospital");


    sessionStorage.removeItem("classification");
    sessionStorage.removeItem("vehicleType");
    sessionStorage.removeItem("make");
    sessionStorage.removeItem("plateNumber");
    sessionStorage.removeItem("minor_grade_level");
    sessionStorage.removeItem("model");
    sessionStorage.removeItem("maneuver");
    sessionStorage.removeItem("damages");
    sessionStorage.removeItem("defects");
    sessionStorage.removeItem("loading");
    sessionStorage.removeItem("insuranceDetails");
    sessionStorage.removeItem("engineNumber");
    sessionStorage.removeItem("chassisNumber");

  }

  const { loading, error, data, refetch} = useQuery(GET_LOCATIONS, {
    fetchPolicy: 'network-only',
    variables: { page, pageSize, sortDirection },
  });

  if (loading) return console.log('Loading ..');
  if (error) return console.log(error.message);

  const totalPages = Math.ceil(data.incident_findAllByFilterWithPagination.paginationDetails.totalCount / pageSize);

  const handlePageChange = (event) => {
    const newPage = Number(event.target.value);
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
      refetch({ page: newPage, pageSize, sortDirection });
    }
  };
  const handleSortChange = (direction) => {
    setSortDirection(direction);
    setPage(1);
    refetch({ page: 1, pageSize, sortDirection });
  };




  const Popup = ({ onClose }) => {
    const [createPost_1, { data_2, error_2 }] = useMutation(UPDATE_POST,
      {
        fetchPolicy: 'network-only',

        onCompleted: (data) => {
          if (data) {
            alert("Data has been added to the DRIVER database");
            window.location.reload();
            // console.log(data);
          }
        },
        onError: (error_2) => {
          console.error("Mutation error:", error_2);
          alert("An error occurred while submitting the form. Please try again.");
        },
      }
    );

    return (
      <div className="popup">
        <div className="popup-inner">
          <h2>Are you sure you want to add this data to the DRIVER database?</h2>
          <button className="yes-btn" onClick={() => {

            const id = sessionStorage.getItem("incident-id");
            const description = sessionStorage.getItem("description");
            const encodeEmail = sessionStorage.getItem("email");
            const collisionType = sessionStorage.getItem("collision");
            const dateTime = sessionStorage.getItem("fromDate");
            const weather = sessionStorage.getItem("weather");
            const light = sessionStorage.getItem("light");
            const lat = sessionStorage.getItem("lat");
            const lng = sessionStorage.getItem("lng");
            const loc = sessionStorage.getItem("address");
            var locationApproximate = sessionStorage.getItem("approximate");
            const mainCause = sessionStorage.getItem("maincause");
            const reportingAgency = sessionStorage.getItem("agency");

            // console.log(id);

            var lat_convert = parseFloat(lat);
            var lng_convert = parseFloat(lng);

            if(locationApproximate === "true"){
                locationApproximate = true;
            }else{
                locationApproximate = false;
            }


            createPost_1({ variables: {id, description, encodeEmail, collisionType, dateTime, weather, light,
            lat_convert, lng_convert, loc, locationApproximate, mainCause,
            reportingAgency
            }});


            onClose();
            //window.location.reload();
            }}>Yes</button>
          <button className="no-btn" onClick={onClose}>No</button>
        </div>
      </div>
    );
  }

  return (
    <div>
    <Header></Header>
    <div>
      <table>
        <thead>
          <tr>
            <th style={{fontSize:'14px'}}>Date and Time</th>
            <th style={{fontSize:'14px'}}>Location</th>
            {/* <th style={{fontSize:'14px'}}>Severity</th> */}
            {/* <th style={{fontSize:'14px', marginLeft: '2px'}}>Attach Official Report</th> */}
            <th style={{fontSize:'14px'}}>Validated Record?</th>
            {/* Add other headers */}
          </tr>
        </thead>
        <tbody>
          {data.incident_findAllByFilterWithPagination.entities.map(incident => (
            <tr key={incident.id}>
            {console.log(incident.id)}
              <td>


              <button className="date-time-button"
                onClick={() => {
                  sessionStorage.setItem("incident-id", incident.id);
                  sessionStorage.setItem("lat", incident.incidentDetails.location.latitude)
                  sessionStorage.setItem("lng", incident.incidentDetails.location.longitude)
                  sessionStorage.setItem("address", incident.incidentDetails.location.address)
                  sessionStorage.setItem("fromDate", incident.incidentDetails.fromDateTime)
                  sessionStorage.setItem("toDate", incident.incidentDetails.toDateTime)
                  sessionStorage.setItem("weather", incident.incidentDetails.weather)
                  sessionStorage.setItem("light", incident.incidentDetails.light)
                  sessionStorage.setItem("severities", incident.incidentDetails.severities)
                  sessionStorage.setItem("maincause", incident.incidentDetails.mainCause)
                  sessionStorage.setItem("collision", incident.incidentDetails.collisionType)
                  sessionStorage.setItem("agency", incident.incidentDetails.reportingAgency)
                  sessionStorage.setItem("email", incident.incidentDetails.encoderEmail)
                  sessionStorage.setItem("description", incident.incidentDetails.description)
                  sessionStorage.setItem("approximate", incident.incidentDetails.locationApproximate)

                  sessionStorage.setItem("notes", incident.notes)

                // Function to handle sessionStorage operations
                const handleSessionStorage = () => {
                incident.parties.forEach(party => {
                party.adults.forEach(adultParty => {
                sessionStorage.setItem("adult_first_name", adultParty.firstName);
                sessionStorage.setItem("adult_middle_name", adultParty.middleName);
                sessionStorage.setItem("adult_last_name", adultParty.lastName);
                sessionStorage.setItem("adult_gender_name", adultParty.gender);
                sessionStorage.setItem("adult_involvement", adultParty.involvement);
                sessionStorage.setItem("adult_address", adultParty.address);
                sessionStorage.setItem("adult_license_number", adultParty.licenseNumber);
                sessionStorage.setItem("adult_age", adultParty.age);
                sessionStorage.setItem("adult_driverError", adultParty.driverError);
                sessionStorage.setItem("adult_alchoholSuspicion", adultParty.alchoholSuspicion);
                sessionStorage.setItem("adult_drugsSuspicion", adultParty.drugsSuspicion);
                sessionStorage.setItem("adult_seatbeltState", adultParty.seatbeltState);
                sessionStorage.setItem("adult_hospital", adultParty.hospital);
                });
                });
              };

                // Call the function to handle sessionStorage operations
                handleSessionStorage();

                  {incident.parties.map(party => (
                      party.adults.map(adultParty => (
                        console.log(adultParty.firstName)
                  ))

                  ))}

                // Function to handle sessionStorage operations
                const handleSessionStorage_Minor = () => {
                incident.parties.forEach(party => {
                party.minors.forEach(minorParty => {
                  sessionStorage.setItem("minor_first_name", minorParty.firstName)
                  sessionStorage.setItem("minor_middle_name", minorParty.middleName)
                  sessionStorage.setItem("minor_last_name", minorParty.lastName)
                  sessionStorage.setItem("minor_gender_name", minorParty.gender)
                  sessionStorage.setItem("minor_grade_level", minorParty.gradeLevel)
                  sessionStorage.setItem("minor_involvement", minorParty.involvement)
                  sessionStorage.setItem("minor_address", minorParty.address)
                  sessionStorage.setItem("minor_license_number", minorParty.licenseNumber)
                  sessionStorage.setItem("minor_age", minorParty.age)
                  sessionStorage.setItem("minor_driverError", minorParty.driverError)
                  sessionStorage.setItem("minor_alchoholSuspicion", minorParty.alchoholSuspicion)
                  sessionStorage.setItem("minor_drugsSuspicion", minorParty.drugsSuspicion)
                  sessionStorage.setItem("minor_seatbeltState", minorParty.seatbeltState)
                  sessionStorage.setItem("minor_hospital", minorParty.hospital)
                });
                });
              };

                // Call the function to handle sessionStorage operations
                handleSessionStorage_Minor();


                  // Function to handle sessionStorage operations
                const handleSessionStorage_Vehicle = () => {
                incident.parties.forEach(party => {
                  sessionStorage.setItem("classification", party.vehicle.classification)
                  sessionStorage.setItem("vehicleType", party.vehicle.vehicleType)
                  sessionStorage.setItem("make", party.vehicle.make)
                  sessionStorage.setItem("plateNumber", party.vehicle.plateNumber)
                  sessionStorage.setItem("model", party.vehicle.model)
                  sessionStorage.setItem("maneuver", party.vehicle.maneuver)
                  sessionStorage.setItem("damages", party.vehicle.damages)
                  sessionStorage.setItem("defects", party.vehicle.defects)
                  sessionStorage.setItem("loading", party.vehicle.loading)
                  sessionStorage.setItem("insuranceDetails", party.vehicle.insuranceDetails)
                  sessionStorage.setItem("engineNumber", party.vehicle.engineNumber)
                  sessionStorage.setItem("chassisNumber", party.vehicle.chassisNumber)

                  console.log(party.vehicle.classification);
                });
              };

              //  Call the function to handle sessionStorage operations
                handleSessionStorage_Vehicle();


                  //  sessionStorage.setItem("crashtype", incident.crashDiagram.crashType)

                  console.log(incident)
                  openDialog1();
                  // console.log(sessionStorage.getItem("incident-id"))
                }}>{incident.incidentDetails.fromDateTime}
                </button>

              </td>
              <td>{incident.incidentDetails.location.address}</td>
              <td>
              <button className="database-btn"
              onClick={() => {
                  sessionStorage.setItem("incident-id", incident.id);
                  sessionStorage.setItem("lat", incident.incidentDetails.location.latitude)
                  sessionStorage.setItem("lng", incident.incidentDetails.location.longitude)
                  sessionStorage.setItem("address", incident.incidentDetails.location.address)
                  sessionStorage.setItem("fromDate", incident.incidentDetails.fromDateTime)
                  sessionStorage.setItem("toDate", incident.incidentDetails.toDateTime)
                  sessionStorage.setItem("weather", incident.incidentDetails.weather)
                  sessionStorage.setItem("light", incident.incidentDetails.light)
                  sessionStorage.setItem("severities", incident.incidentDetails.severities)
                  sessionStorage.setItem("maincause", incident.incidentDetails.mainCause)
                  sessionStorage.setItem("collision", incident.incidentDetails.collisionType)
                  sessionStorage.setItem("agency", incident.incidentDetails.reportingAgency)
                  sessionStorage.setItem("email", incident.incidentDetails.encoderEmail)
                  sessionStorage.setItem("description", incident.incidentDetails.description)
                  sessionStorage.setItem("approximate", incident.incidentDetails.locationApproximate)

              togglePopup()

              }

              }>ADD to DRIVER dbase</button>
              {isPopupOpen && <Popup onClose={togglePopup} />}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <button className='pagination-button' onClick={() => setPage(prev => Math.max(prev - 1, 1))} disabled={page === 1}>
          Previous
        </button>
        <input
          type="number"
          value={page}
          onChange={handlePageChange}
          min="1"
          max={totalPages}
          style={{ width: '60px', marginLeft: '5px' }}
        />
        <span>&nbsp;&nbsp; / {totalPages}&nbsp;&nbsp;</span>
        <button className='next-button'
          onClick={() => {
            const newPage = Math.min(page + 1, totalPages);
            setPage(newPage);
            refetch({ page: newPage, pageSize, sortDirection});
          }}
          disabled={page === totalPages}
        >
          Next
        </button>

        <button className='asc-button' onClick={() => handleSortChange('ASC')}>
          Sort Ascending
        </button>
        <button className='desc-button' onClick={() => handleSortChange('DESC')}>
          Sort Descending
        </button>
      </div>
    </div>
    <ViewRecord open={dialogIsOpen1} onClose={closeDialog1}></ViewRecord>
    <Outlet></Outlet>
    </div>
);
};

export default Stph;