import {gql} from '@apollo/client'

export const CREATE_POST_4 = gql`
mutation CreatePost ($description: String!, $encodeEmail: String!, $dateTime: DateTime!, $collisionType: CollisionTypeEnum, $weather: WeatherEnum!, $light: LightEnum, $lat_convert: Float!, $lng_convert: Float!, $loc: String, $locationApproximate: Boolean!, $mainCause: MainCauseEnum, $reportingAgency: AgencyEnum, $crashType:  CrashTypeEnum!,  $notes: String,
   $chassisNumber: String!, $classification: VehicleClassificationEnum, $engineNumber: String!, $insuranceDetails: String!, $loading: LoadingEnum!,
   $make: String!, $maneuver: ManeuverEnum!, $model: String!, $plateNumber: String!, $vehicleType: VehicleTypeEnum, $partyName: String, $partyIndex: Float!,
   $m_grade: GradeLevelEnum, $m_address: String!, $m_age: Float, $m_alchoholSuspicion: Boolean!, $m_drugsSuspicion: Boolean!, 
   $m_driverError: DriverErrorEnum, $m_firstName: String!, $m_middleName: String, $m_lastName: String!, $m_gender: GenderEnum,
   $m_seatbeltState: WearState, $m_hospital: String, $m_injury: InjuryEnum!, $m_involvement: InvolvementEnum!, $m_licensenumber: String
) {
  incident_add(entity: {
          isVerified: false,
          incidentDetails: {
              collisionType: $collisionType,
              fromDateTime: $dateTime,
              toDateTime: $dateTime,
              description: $description,
              encoderEmail: $encodeEmail,
              light: $light,
              location: {
                  latitude: $lat_convert,
                  longitude: $lng_convert,
                  address: $loc
              },
              locationApproximate: $locationApproximate,
              mainCause: $mainCause,
              reportingAgency: $reportingAgency,
              severities: INJURY,
              weather: $weather
          },
          crashDiagram: {
            crashType: $crashType,
            movementCode: ""
          },
          notes: $notes,
          parties: {
            partyIndex: $partyIndex,
            name: $partyName,
            vehicle: {
              classification: $classification,
              vehicleType: $vehicleType,
              make: $make,
              plateNumber: $plateNumber,
              model: $model,
              maneuver: $maneuver,
              damages: FRONT,
              defects: NONE,
              loading: $loading,
              insuranceDetails: $insuranceDetails,
              engineNumber: $engineNumber,
              chassisNumber: $chassisNumber
            }
            minors: {
              gradeLevel: $m_grade,
              involvement: $m_involvement,
              firstName: $m_firstName,
              middleName: $m_middleName
              lastName: $m_lastName
              address: $m_address,
              gender: $m_gender,
              license_number: $m_licensenumber,
              age: $m_age,
              driverError: $m_driverError,
              injury: $m_injury,
              alchoholSuspicion: $m_alchoholSuspicion,
              drugsSuspicion: $m_drugsSuspicion,
              seatbeltState: $m_seatbeltState,
              hospital: $m_hospital
            }

          }
      }
  ) {
      id
      incidentDetails {
        location {
            latitude
            longitude
          }
        fromDateTime
        toDateTime
        weather
        light
        severities
        mainCause
        collisionType
        reportingAgency
        encoderEmail
        description
        locationApproximate
      }
      parties {
        id
        name
        minors {
          gradeLevel
          involvement
          firstName
          lastName
          address
          gender
          licenseNumber
          age
          driverError
          injury
          alchoholSuspicion
          drugsSuspicion
          seatbeltState
          hospital
        }
        vehicle {
          classification
          vehicleType
          make
          plateNumber
          model
          maneuver
          damages
          defects
          loading
          insuranceDetails
          engineNumber
          chassisNumber
        }
        photos{
          url
        }
      }
      notes
      crashDiagram {
        id
        crashType
        movementCode
      }
      isVerified
    }
  }
`;

