import React from "react";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = (props) => <div {...props}><img src="http://maps.google.com/mapfiles/ms/icons/blue.png" alt="marker"/></div>;

const Marker = ({ markerUrl }) => (
  <div style={{
    width: '32px',
    height: '32px',
    backgroundImage: `url(${markerUrl})`,
    backgroundSize: 'cover',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer'
  }} />
);

export default function SimpleMap(){

  const defaultProps = {
    center: {
      lat: parseFloat(sessionStorage.getItem("lat")),
      lng: parseFloat(sessionStorage.getItem("lng"))
    },
    zoom: 17
  };

  const storedLat = parseFloat(sessionStorage.getItem('lat'));
  const storedLng = parseFloat(sessionStorage.getItem('lng'));

  const specificMarker = {
    lat: storedLat,
    lng: storedLng
  };

    // State for marker position
    const [markerPosition, setMarkerPosition] = React.useState(defaultProps.center);

    // Handle map center change
    const handleMapChange = ({ center }) => {
      setMarkerPosition(center);
    };
  


  return (
    // Important! Always set the container height explicitly
    <div className="record-map">
    <div className="record-map-div">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDmbDwONw8uAMhun-c5bnkcKS0KA6_Gmas" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onChange={handleMapChange}
        options={{ disableDefaultUI: true, zoomControl: false }}
      >
      <Marker
          lat={specificMarker.lat}
          lng={specificMarker.lng}
          markerUrl={'http://maps.google.com/mapfiles/ms/icons/blue.png'} // Custom marker image URL
        />
      </GoogleMapReact>
    </div>
    </div>
  );
}

