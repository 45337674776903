
import {gql, useQuery} from '@apollo/client';
import * as React from 'react';
import {Outlet} from 'react-router-dom';
import Header from '../components/Header';
import ViewRecord from '../components/ViewRecord';
import "./list.css";

//GRAPHQL QUERY
const GET_LOCATIONS = gql`
query sampleFindAll($page: Float!, $startDate: DateTime, $endDate: DateTime, $childChecker: Boolean, $sortDirection: String) {
    incident_findAllByFilterWithPagination(filters: {
      fromDate: $startDate,
      toDate: $endDate,
      isVerified: true,
      hasMinors: $childChecker
    }, options: {
      paginationOption: {
        page: $page,
        resultsPerPage: 30
      }, sortOptions: {
        by: "createdAt",
        direction: $sortDirection
      }
    }) {
      paginationDetails {
        page
        resultsPerPage
        totalCount
        hasPreviousPage
        hasNextPage
        previousPage
        nextPage
        numberOfPages
      }
      entities {
          id
          isVerified
          incidentDetails {
            id
            location {
              latitude
              longitude
              address
            }
            collisionType
            description
            encoderEmail
            light
            locationApproximate
            mainCause
            reportingAgency
            severities
            weather
            fromDateTime
            toDateTime
          }
      parties {
        id
        minors {
          id
          firstName
          middleName
          lastName
          gender
          gradeLevel
          involvement
          address
          licenseNumber
          age
          driverError
          alchoholSuspicion
          drugsSuspicion
          seatbeltState
          hospital
        } 
      }
          notes
      }
      paginationDetails {
        page
        resultsPerPage
      }
    }
}`;


//GRAPHQL QUERY
const GET_LOCATIONS_1 = gql`
query sampleFindAll($page: Float!, $startDate: DateTime, $endDate: DateTime, $sortDirection: String) {
    incident_findAllByFilterWithPagination(filters: {
      fromDate: $startDate,
      toDate: $endDate,
      isVerified: true,
    }, options: {
      paginationOption: {
        page: $page,
        resultsPerPage: 30
      }, sortOptions: {
        by: "createdAt",
        direction: $sortDirection
      }
    }) {
      paginationDetails {
        page
        resultsPerPage
        totalCount
        hasPreviousPage
        hasNextPage
        previousPage
        nextPage
        numberOfPages
      }
      entities {
          id
          isVerified
          incidentDetails {
            id
            location {
              latitude
              longitude
              address
            }
            collisionType
            description
            encoderEmail
            light
            locationApproximate
            mainCause
            reportingAgency
            severities
            weather
            fromDateTime
            toDateTime
          }
      parties {
        id
        minors {
          id
          firstName
          middleName
          lastName
          gender
          gradeLevel
          involvement
          address
          licenseNumber
          age
          driverError
          alchoholSuspicion
          drugsSuspicion
          seatbeltState
          hospital
        }
      }
          notes
      }
      paginationDetails {
        page
        resultsPerPage
      }
    }
}`;


function List() {

  const [dialogIsOpen1, setDialogIsOpen1] = React.useState(false)
  const openDialog1 = () => setDialogIsOpen1(true)
  // const [searchQuery, setSearchQuery] = React.useState('');

  const [page, setPage] = React.useState(1);

  var startDate = sessionStorage.getItem("start-date-filter");
  var endDate = sessionStorage.getItem("end-date-filter");
  const [sortDirection, setSortDirection] = React.useState('DESC');
  

  const pageSize = 30; // Number of items per page

  const closeDialog1 = () => {
    setDialogIsOpen1(false)

    sessionStorage.removeItem("adult_first_name");
    sessionStorage.removeItem("adult_middle_name");
    sessionStorage.removeItem("adult_last_name");
    sessionStorage.removeItem("adult_gender_name");
    sessionStorage.removeItem("adult_involvement");
    sessionStorage.removeItem("adult_address");
    sessionStorage.removeItem("adult_license_number");
    sessionStorage.removeItem("adult_age");
    sessionStorage.removeItem("adult_driverError");
    sessionStorage.removeItem("adult_alchoholSuspicion");
    sessionStorage.removeItem("adult_drugsSuspicion");
    sessionStorage.removeItem("adult_seatbeltState");
    sessionStorage.removeItem("adult_hospital");

    sessionStorage.removeItem("minor_first_name");
    sessionStorage.removeItem("minor_middle_name");
    sessionStorage.removeItem("minor_last_name");
    sessionStorage.removeItem("minor_gender_name");
    sessionStorage.removeItem("minor_grade_level");
    sessionStorage.removeItem("minor_involvement");
    sessionStorage.removeItem("minor_address");
    sessionStorage.removeItem("minor_license_number");
    sessionStorage.removeItem("minor_age");
    sessionStorage.removeItem("minor_age");
    sessionStorage.removeItem("minor_alchoholSuspicion");
    sessionStorage.removeItem("minor_drugsSuspicion");
    sessionStorage.removeItem("minor_seatbeltState");
    sessionStorage.removeItem("minor_hospital");


    sessionStorage.removeItem("classification");
    sessionStorage.removeItem("vehicleType");
    sessionStorage.removeItem("make");
    sessionStorage.removeItem("plateNumber");
    sessionStorage.removeItem("minor_grade_level");
    sessionStorage.removeItem("model");
    sessionStorage.removeItem("maneuver");
    sessionStorage.removeItem("damages");
    sessionStorage.removeItem("defects");
    sessionStorage.removeItem("loading");
    sessionStorage.removeItem("insuranceDetails");
    sessionStorage.removeItem("engineNumber");
    sessionStorage.removeItem("chassisNumber");
  }


  var childFilter = sessionStorage.getItem("childFilter");
  var queryToUse;
  var childChecker;

  if (childFilter === "true"){
    childChecker = true;
    queryToUse = GET_LOCATIONS;
  } else {
    childChecker = false;
    queryToUse = GET_LOCATIONS_1;
  }

  // const { loading, error, data } = useQuery(GET_LOCATIONS);

  const {loading, error, data, refetch} = useQuery(queryToUse, {
    fetchPolicy: 'network-only',
    variables: {page, pageSize, startDate, endDate, childChecker, sortDirection},
  });

  const handlePageChange = (event) => {
    const newPage = Number(event.target.value);
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
      refetch({ page: newPage, pageSize, startDate, endDate, childChecker, sortDirection });
    }
  };
  const handleSortChange = (direction) => {
    setSortDirection(direction);
    setPage(1);
    refetch({ page: 1, pageSize, startDate, endDate, childChecker, sortDirection });
  };


  if (loading) return console.log('Loading ..');
  if (error) return console.log(error.message);

  // const { items, totalItems } = data.incident_findAllWithPagination;
  const totalPages = Math.ceil(data.incident_findAllByFilterWithPagination.paginationDetails.totalCount / pageSize);


  return (
    <div>
      <Header></Header>
      <div>
        <table>
          <thead>
            <tr>
              <th>Date and Time</th>
              <th>Location</th>
              <th>Severity</th>
              {/* Add other headers */}
            </tr>
          </thead>
          <tbody>
            {data.incident_findAllByFilterWithPagination.entities.map(incident => (

              <tr key={incident.id}>
                {/* {incident.incidentDetails.location.address.toLowerCase().includes(searchQuery.toLowerCase())} */}
                <td>
                  <button className="date-time-button"
                    onClick={() => {
                      sessionStorage.setItem("incident-id", incident.id);
                      sessionStorage.setItem("lat", incident.incidentDetails.location.latitude)
                      sessionStorage.setItem("lng", incident.incidentDetails.location.longitude)
                      sessionStorage.setItem("address", incident.incidentDetails.location.address)
                      sessionStorage.setItem("fromDate", incident.incidentDetails.fromDateTime)
                      sessionStorage.setItem("toDate", incident.incidentDetails.toDateTime)
                      sessionStorage.setItem("weather", incident.incidentDetails.weather)
                      sessionStorage.setItem("light", incident.incidentDetails.light)
                      sessionStorage.setItem("severities", incident.incidentDetails.severities)
                      sessionStorage.setItem("maincause", incident.incidentDetails.mainCause)
                      sessionStorage.setItem("collision", incident.incidentDetails.collisionType)
                      sessionStorage.setItem("agency", incident.incidentDetails.reportingAgency)
                      sessionStorage.setItem("email", incident.incidentDetails.encoderEmail)
                      sessionStorage.setItem("description", incident.incidentDetails.description)
                      sessionStorage.setItem("approximate", incident.incidentDetails.locationApproximate)

                      sessionStorage.setItem("notes", incident.notes)
                
                // Function to handle sessionStorage operations
                const handleSessionStorage_Minor = () => {
                  incident.parties.forEach(party => {
                  party.minors.forEach(minorParty => {
                    sessionStorage.setItem("minor_first_name", minorParty.firstName)
                    sessionStorage.setItem("minor_middle_name", minorParty.middleName)
                    sessionStorage.setItem("minor_last_name", minorParty.lastName)
                    sessionStorage.setItem("minor_gender_name", minorParty.gender)
                    sessionStorage.setItem("minor_grade_level", minorParty.gradeLevel)
                    sessionStorage.setItem("minor_involvement", minorParty.involvement)
                    sessionStorage.setItem("minor_address", minorParty.address)
                    sessionStorage.setItem("minor_license_number", minorParty.licenseNumber)
                    sessionStorage.setItem("minor_age", minorParty.age)
                    sessionStorage.setItem("minor_driverError", minorParty.driverError)
                    sessionStorage.setItem("minor_alchoholSuspicion", minorParty.alchoholSuspicion)
                    sessionStorage.setItem("minor_drugsSuspicion", minorParty.drugsSuspicion)
                    sessionStorage.setItem("minor_seatbeltState", minorParty.seatbeltState)
                    sessionStorage.setItem("minor_hospital", minorParty.hospital)
                  });
                  });
                };
    
                  // Call the function to handle sessionStorage operations
                  handleSessionStorage_Minor();
                  openDialog1();
                    }}>{incident.incidentDetails.fromDateTime}
                  </button>

                </td>

                <td>{incident.incidentDetails.location.address}</td>
                <td>{incident.incidentDetails.severities.join(', ')}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <button className='pagination-button' onClick={() => setPage(prev => Math.max(prev - 1, 1))} disabled={page === 1}>
            Previous
          </button>
          <input
          type="number"
          value={page}
          onChange={handlePageChange}
          min="1"
          max={totalPages}
          style={{ width: '60px', marginLeft: '5px' }}
        />
        <span>&nbsp;&nbsp; / {totalPages}&nbsp;&nbsp;</span>
        <button className='next-button'
          onClick={() => {
            const newPage = Math.min(page + 1, totalPages);
            setPage(newPage);
            refetch({ page: newPage, pageSize, startDate, endDate, childChecker, sortDirection});
          }}
          disabled={page === totalPages}
        >
          Next
        </button>

        <button className='asc-button' onClick={() => handleSortChange('ASC')}>
          Sort Ascending
        </button>
        <button className='desc-button' onClick={() => handleSortChange('DESC')}>
          Sort Descending
        </button>
        </div>
      </div>
      <ViewRecord open={dialogIsOpen1} onClose={closeDialog1}></ViewRecord>
      <Outlet></Outlet>
    </div>
  );
};

export default List;
