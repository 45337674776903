import {useApiRepository} from './apiRepository'
import {useCallback, useState} from './utils'

export const useViewModel = () => {

  // DEPENDENCIES

  const apiRepository = useApiRepository()

  // STATE

  const [name, setName] = useState('')
  const [age, setAge, onAgeInput] = useState('')
  // NOTE: unused array elements can be left blank in order not to trigger eslint warning for unused variables
  const [description, , onDescriptionInput] = useState('')

  // EVENT HANDLERS

  const onNameInput = useCallback((event) => {
    setName(event.target.value)

    apiRepository.createPost('')
  }, [apiRepository])

  // RETURNS

  return {
    name, onNameInput,
    age, onAgeInput,
    description, onDescriptionInput,
  }
}