import {gql, useMutation} from '@apollo/client'

const CREATE_POST = gql`
mutation CreatePost ($description: String!, $encodeEmail: String!) {
  incident_add(entity: {
          incidentDetails: {
              collisionType: HEAD_ON,
              fromDateTime: "2024-02-06T08:41:10.184Z",
              toDateTime: "2024-02-06T08:41:10.184Z",
              description: $description,
              encoderEmail: $encodeEmail,
              light: DAWN,
              location: {
                  latitude: 0,
                  longitude: 0
              },
              locationApproximate: true,
              mainCause: HUMAN_ERROR,
              reportingAgency: PNP,
              severities: FATAL,
              weather: CLEAR_NIGHT
          },
          crashDiagram: {
            crashType: MANEUVERING,
            movementCode: ""
          },
          notes: "",
          parties: []

      }
  ) {
      id
      incidentDetails {
        location {
            latitude
            longitude
          }
        fromDateTime
        toDateTime
        weather
        light
        severities
        mainCause
        collisionType
        reportingAgency
        encoderEmail
        description
        locationApproximate
      }
      parties {
        id
        name
        adults {
            involvement
            firstName
            lastName
            address
            gender
            licenseNumber
            age
            driverError
            alchoholSuspicion
            seatbeltState
            hospital
          }
        minors {
          gradeLevel
          involvement
          firstName
          lastName
          address
          gender
          licenseNumber
          age
          driverError
          alchoholSuspicion
          seatbeltState
          hospital
        }
        vehicle {
          classification
          vehicleType
          make
          plateNumber
          model
          maneuver
          damages
          defects
          loading
          insuranceDetails
          engineNumber
          chassisNumber
        }
        photos{
          url
        }
      }
      notes
      crashDiagram {
        id
        crashType
        movementCode
      }
      isVerified
    }
  }
`

export const useApiRepository = () => {
  return {
    createPost: useMutation(CREATE_POST)
  }
}