import React, { useState } from 'react';
import Header from '../components/Header';
import { useQuery } from '@apollo/client';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';
import {
  CHART_PD_2017, CHART_PD_2018, CHART_PD_2019,
  CHART_FATAL_2017, CHART_FATAL_2018, CHART_FATAL_2019,
  CHART_INJURY_2017, CHART_INJURY_2018, CHART_INJURY_2019
} from '../query_and_mutations/charts_mutation';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Charts = () => {
  const [chartType, setChartType] = useState('bar');

  const { loading: loadingPD_2017, error: errorPD_2017, data: PD_2017 } = useQuery(CHART_PD_2017);
  const { loading: loadingPD_2018, error: errorPD_2018, data: PD_2018 } = useQuery(CHART_PD_2018);
  const { loading: loadingPD_2019, error: errorPD_2019, data: PD_2019 } = useQuery(CHART_PD_2019);

  const { loading: loadingFatal_2017, error: errorFatal_2017, data: Fatal_2017 } = useQuery(CHART_FATAL_2017);
  const { loading: loadingFatal_2018, error: errorFatal_2018, data: Fatal_2018 } = useQuery(CHART_FATAL_2018);
  const { loading: loadingFatal_2019, error: errorFatal_2019, data: Fatal_2019 } = useQuery(CHART_FATAL_2019);

  const { loading: loadingInjury_2017, error: errorInjury_2017, data: Injury_2017 } = useQuery(CHART_INJURY_2017);
  const { loading: loadingInjury_2018, error: errorInjury_2018, data: Injury_2018 } = useQuery(CHART_INJURY_2018);
  const { loading: loadingInjury_2019, error: errorInjury_2019, data: Injury_2019 } = useQuery(CHART_INJURY_2019);

  console.log('PD_2017 data:', PD_2017);

  const pd_2017 = PD_2017?.incident_findAllByFilterWithPagination.paginationDetails.totalCount || 0;
  const pd_2018 = PD_2018?.incident_findAllByFilterWithPagination.paginationDetails.totalCount || 0;
  const pd_2019 = PD_2019?.incident_findAllByFilterWithPagination.paginationDetails.totalCount || 0;

  const fatal_2017 = Fatal_2017?.incident_findAllByFilterWithPagination.paginationDetails.totalCount || 0;
  const fatal_2018 = Fatal_2018?.incident_findAllByFilterWithPagination.paginationDetails.totalCount || 0;
  const fatal_2019 = Fatal_2019?.incident_findAllByFilterWithPagination.paginationDetails.totalCount || 0;

  const injury_2017 = Injury_2017?.incident_findAllByFilterWithPagination.paginationDetails.totalCount || 0;
  const injury_2018 = Injury_2018?.incident_findAllByFilterWithPagination.paginationDetails.totalCount || 0;
  const injury_2019 = Injury_2019?.incident_findAllByFilterWithPagination.paginationDetails.totalCount || 0;

  const data2017 = [pd_2017, fatal_2017, injury_2017]; // Example data for 2017
  const data2018 = [pd_2018, fatal_2018, injury_2018]; // Example data for 2018
  const data2019 = [pd_2019, fatal_2019, injury_2019]; // Example data for 2019

  const labels = ['Property Damage', 'Fatal', 'Injury'];

  const allData = {
    labels: labels,
    datasets: [
      {
        label: '2017',
        data: data2017,
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(75, 192, 192, 0.2)'
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(75, 192, 192, 1)'
        ],
        borderWidth: 1,
        fill: chartType === 'area', // Fill for area chart
      },
      {
        label: '2018',
        data: data2018,
        backgroundColor: [
          'rgba(153, 102, 255, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(153, 102, 255, 0.2)'
        ],
        borderColor: [
          'rgba(153, 102, 255, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(153, 102, 255, 1)'
        ],
        borderWidth: 1,
        fill: chartType === 'area', // Fill for area chart
      },
      {
        label: '2019',
        data: data2019,
        backgroundColor: [
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255, 159, 64, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1,
        fill: chartType === 'area', // Fill for area chart
      },
    ],
  };

  const toggleChartType = () => {
    setChartType(prevType => {
      switch (prevType) {
        case 'bar':
          return 'pie';
        case 'pie':
          return 'area';
        case 'area':
          return 'bar';
        default:
          return 'bar';
      }
    });
  };

  return (
    <div>
      <Header />
      <div style={{ width: '60%', margin: '0 auto' }}>
        <h1>Incident Data by Severity</h1>
        <button onClick={toggleChartType} style={{ width: '200px', height: '30px', fontSize: '14px', marginBottom: '20px' }}>
          Switch to {chartType === 'bar' ? 'Pie Chart' : chartType === 'pie' ? 'Area Chart' : 'Bar Chart'}
        </button>
        <div style={{ width: '60%', height: '40%' }}> {/* Adjust the width and height as needed */}
          {chartType === 'bar' && <Bar data={allData} />}
          {chartType === 'pie' && <Pie data={allData} />}
          {chartType === 'area' && <Line data={allData} options={{ elements: { line: { fill: true } } }} />}
        </div>
      </div>
    </div>
  );
};

export default Charts;
