import React from 'react'
import {useViewModel} from "./viewModel"

export const View = (props) => {

  // DEPENDENCIES

  const viewModel = useViewModel()

  // RENDER

  return <>
    <h1>Refector Demo</h1>
    <label>Name</label>
    <input type="text" value={viewModel.name} onInput={viewModel.onNameInput} />
    <div>{viewModel.name}</div>
  </>
}