import React, {useEffect} from 'react'

export const useCallback = (callback, dependencies = []) => {
  // eslint-disable-next-line
  return React.useCallback(callback, dependencies)
}

export const useAsyncEffect = (fn, dependencies = []) => {
  return useEffect(() => {
    fn()
    // eslint-disable-next-line
  }, dependencies)
}

export const useState = (initialState) => {
  const [state, setState] = React.useState(initialState)

  const onInput =  (event) => {
    setState(event.target.value)
  }

  return [
    state,
    setState,
    onInput,
  ]
}