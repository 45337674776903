import { menuItemsData } from "../MenuItemsData";
import MenuItems from "./MenuItems";
import { CiFilter } from "react-icons/ci";
import { BsFillPinMapFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { BsListNested } from "react-icons/bs";
import * as React from 'react';
import FilterDialog from './FilterDialog'
import DateFilter from './DateFilter'
import ReportDialog from './ReportDialog'
import { List } from "@mui/material";


const Navbar = () => {

  var item_value = sessionStorage.getItem("login_user");
  var selected_menu = sessionStorage.getItem("selected_menu");

  const navigate = useNavigate();

  const handleChange_privacy = (e) => {
    sessionStorage.setItem("selected_menu", "Privacy");
    navigate('/privacy');
  }

  const handleChange_stph = (e) => {
    sessionStorage.setItem("selected_menu", "Data-review");
    navigate('/data-review');
  }

  const [dialogIsOpen, setDialogIsOpen] = React.useState(false)
  const openDialog = () => setDialogIsOpen(true)
  const closeDialog = () => setDialogIsOpen(false)

  const openDialog1 = (e) => {
    sessionStorage.setItem("record-button", "clicked");
  }

  const [dialogIsOpen2, setDialogIsOpen2] = React.useState(false)
  const openDialog2 = () => setDialogIsOpen2(true)
  const closeDialog2 = () => setDialogIsOpen2(false)

  var value;
  var value1;
  var value2;

  if(item_value === 'false'){
    value = false;
  } else {
    value = true;
  }


  if(selected_menu === 'List' || selected_menu === 'Reports' || selected_menu === 'Charts' || selected_menu === 'Privacy' || selected_menu === 'Data-review'){
    value1 = true;
  } else {
    value1 = false;
  }

  if(selected_menu === 'Reports'){
    value2 = false;
  } else {
    value2 = true;
  }

  const depthLevel = 0;

  return (
    <nav className="desktop-nav">
      <ul className="menus">
      {menuItemsData.map((menu, index) => {
          return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
        })}

        <List>
        <button className="filter-box" onClick={openDialog}>
        <div style={{marginTop: '5px'}}>
          <CiFilter/>
        </div>
        </button>
        <DateFilter open={dialogIsOpen} onClose={closeDialog}></DateFilter>
        </List>

        <List>
        <button className="record-box" onClick={openDialog1} hidden={value? true : false} > 
        <div style={{marginTop: '5px'}}>
          <BsFillPinMapFill/>
        </div>
        </button>
        </List>

        <List>
        <button className="report-box" onClick={openDialog2} hidden={value2? true : false} > 
        <div style={{marginTop: '5px'}}>
          <BsListNested/>
        </div>
        </button>
        <ReportDialog open={dialogIsOpen2} onClose={closeDialog2}></ReportDialog>
        </List>
        

        <button className="privacy-box" onClick={handleChange_privacy}>
        <p>Privacy</p>
        </button>
        <button className="stph-box" onClick={handleChange_stph} hidden={value? true : false} >
        <p>Data Review</p></button>


      
      </ul>
    </nav>
  );
};

export default Navbar;



