import {GoogleLogin} from '@react-oauth/google';
import * as React from 'react';
import {useState} from "react";
import {useNavigate} from 'react-router-dom';
import PrivacyDialog from "../components/PrivacyDialog";
import classes from "./login.module.css";



function LoginForm() {

  const [dialogIsOpen, setDialogIsOpen] = React.useState(false)
  const openDialog = () => setDialogIsOpen(true)
  const closeDialog = () => setDialogIsOpen(false)
  const [userNameInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmailInput(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setPasswordInput(e.target.value);
  }

  const handleLoginSubmit = (e) => {
    e.preventDefault();


    let Account1 = {
      username: process.env.REACT_APP_DOTR_ACCOUNT_USERNAME,
      password: process.env.REACT_APP_DOTR_ACCOUNT_PASSWORD
    }

    let Account2 = {
      username: process.env.REACT_APP_PNP_ADMIN_ACCOUNT_USERNAME,
      password: process.env.REACT_APP_PNP_ADMIN__ACCOUNT_PASSWORD
    }

    let Account3 = {
      username: process.env.REACT_APP_LGU_ADMIN_ACCOUNT_USERNAME,
      password: process.env.REACT_APP_LGU_ADMIN__ACCOUNT_PASSWORD
    }

    let Account4 = {
      username: process.env.REACT_APP_MMDA_ADMIN_ACCOUNT_USERNAME,
      password: process.env.REACT_APP_MMDA_ADMIN__ACCOUNT_PASSWORD
    }

    let Account5 = {
      username: process.env.REACT_APP_DPWH_ADMIN_ACCOUNT_USERNAME,
      password: process.env.REACT_APP_DPWH_ADMIN__ACCOUNT_PASSWORD
    }

    if ((userNameInput === Account1.username || userNameInput === Account2.username || userNameInput === Account3.username || userNameInput === Account4.username || userNameInput === Account5.username) && (passwordInput === Account1.password || passwordInput === Account2.password | passwordInput === Account3.password | passwordInput === Account4.password | passwordInput === Account5.password)) {
      alert('Login Successful!!');

      sessionStorage.setItem("login_user", "false");
      sessionStorage.setItem("selected_menu1", "Map");
      sessionStorage.setItem("selected_menu2", "City/Provinces");
      sessionStorage.setItem("selected_menu3", "All");
      sessionStorage.setItem("start-date-filter", "2015-01-01 00:00:00");
      sessionStorage.setItem("end-date-filter", "2025-01-01 23:59:59");
      sessionStorage.setItem('childFilter', "false");
      
      navigate('driver');
      window.location.reload();

    } else {
      alert('Invalid username or password!!');
    }
  }


  return (
    <div className={classes.div}>
      <button className="button_privacy" onClick={openDialog}>Privacy</button>
      <PrivacyDialog open={dialogIsOpen} onClose={closeDialog}></PrivacyDialog>
      <form autoComplete="off" onSubmit={handleLoginSubmit}>
        <div>
          <h2>DRIVER</h2>
          <p style={{fontSize: "13px", marginTop: "20px", marginBottom: "-10px"}}>Username</p>
          <input
            className={classes.input}
            type="text"
            id="user-name"
            name="user-name"
            autoComplete="on"
            onChange={handleEmailChange}
          ></input>
        </div>
        <div>
          <p style={{fontSize: "13px", marginTop: "20px", marginBottom: "-10px"}}>Password</p>
          <input
            className={classes.input}
            type="password"
            id="user-password"
            name="user-password"
            autoComplete="off"
            onChange={handlePasswordChange}
          ></input>
        </div>
        <button
          className={classes.loginBtn}>
          Log in
        </button> <br></br>
      </form>

      <div className={classes.loginBtn1}>

        <GoogleLogin
          onSuccess={credentialResponse => {
            sessionStorage.setItem("login_user", "true");
            sessionStorage.setItem("selected_menu1", "Map");
            sessionStorage.setItem("selected_menu2", "City/Provinces");
            sessionStorage.setItem("selected_menu3", "All");
            sessionStorage.setItem("start-date-filter", "2015-01-01 00:00:00");
            sessionStorage.setItem("end-date-filter", "2025-01-01 23:59:59");
            sessionStorage.setItem('childFilter', "false");
            
            navigate('driver');
            window.location.reload();

          }}
          onError={() => {
            console.log('Login Failed');
          }} />;
      </div>
    </div>
  );


}



export default LoginForm;