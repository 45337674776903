import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useEffect } from 'react';
import RecordMap from '../screens/recordMap';


function ViewRecord(props) {
    const { open, onClose } = props

    const [activeWindow, setActiveWindow] = useState('main');
    const [activeWindow1, setActiveWindow1] = useState('main');
    const [openParties, setOpenParties] = React.useState(true)
    const [peopleOpen, setpeopleOpen] = React.useState(true);
    const [childOpen, setChildOpen] = React.useState(true)
    const [isHidden, setIsHidden] = useState(false);

    useEffect(() => {
      const sessionName = sessionStorage.getItem("login_user");
      if (sessionName === "true") {
        setIsHidden(true);
      }
    }, []);


    const handleOpenPeople = (e) => {
      setpeopleOpen(false);
    }

    const handleOpenChild = (e) => {
      setChildOpen(false);
    }

    var item_value = sessionStorage.getItem("login_user");

    var lat = sessionStorage.getItem("lat");
    var lng = sessionStorage.getItem("lng");
    var address = sessionStorage.getItem("address");
    var fromDate = sessionStorage.getItem("fromDate");
    var toDate = sessionStorage.getItem("toDate");
    var weather = sessionStorage.getItem("weather");
    var light = sessionStorage.getItem("light");
    var severities = sessionStorage.getItem("severities");
    var maincause = sessionStorage.getItem("maincause");
    var collision = sessionStorage.getItem("collision");
    var agency = sessionStorage.getItem("agency");
    var email = sessionStorage.getItem("email");
    var description = sessionStorage.getItem("description");
    var approximate = sessionStorage.getItem("approximate");

    var notes = sessionStorage.getItem("notes");

    var adult_first_name = sessionStorage.getItem("adult_first_name");
    var adult_middle_name = sessionStorage.getItem("adult_middle_name");
    var adult_last_name = sessionStorage.getItem("adult_last_name");
    var adult_gender_name = sessionStorage.getItem("adult_gender_name");
    var adult_involvement = sessionStorage.getItem("adult_involvement");
    var adult_address = sessionStorage.getItem("adult_address");
    var adult_license_number = sessionStorage.getItem("adult_license_number");
    var adult_age = sessionStorage.getItem("adult_age");
    var adult_driverError = sessionStorage.getItem("adult_driverError");
    var adult_alchoholSuspicion = sessionStorage.getItem("adult_alchoholSuspicion");
    var adult_drugsSuspicion = sessionStorage.getItem("adult_drugsSuspicion");
    var adult_seatbeltState = sessionStorage.getItem("adult_seatbeltState");
    var adult_hospital = sessionStorage.getItem("adult_hospital");

    var minor_first_name = sessionStorage.getItem("minor_first_name");
    var minor_middle_name = sessionStorage.getItem("minor_middle_name");
    var minor_last_name = sessionStorage.getItem("minor_last_name");
    var minor_gender_name = sessionStorage.getItem("minor_gender_name");
    var minor_grade_level = sessionStorage.getItem("minor_grade_level");
    var minor_involvement = sessionStorage.getItem("minor_involvement");
    var minor_address = sessionStorage.getItem("minor_address");
    var minor_license_number = sessionStorage.getItem("minor_license_number");
    var minor_age = sessionStorage.getItem("minor_age");
    var minor_driverError = sessionStorage.getItem("minor_driverError");
    var minor_alchoholSuspicion = sessionStorage.getItem("minor_alchoholSuspicion");
    var minor_drugsSuspicion = sessionStorage.getItem("minor_drugsSuspicion");
    var minor_seatbeltState = sessionStorage.getItem("minor_seatbeltState");
    var minor_hospital = sessionStorage.getItem("minor_hospital");


    var classification = sessionStorage.getItem("classification");
    var vehicleType = sessionStorage.getItem("vehicleType");
    var make = sessionStorage.getItem("make");
    var plateNumber = sessionStorage.getItem("plateNumber");
    var model = sessionStorage.getItem("model");
    var maneuver = sessionStorage.getItem("maneuver");
    var damages = sessionStorage.getItem("damages");
    var defects = sessionStorage.getItem("defects");
    var loading = sessionStorage.getItem("loading");
    var insuranceDetails = sessionStorage.getItem("insuranceDetails");
    var engineNumber = sessionStorage.getItem("engineNumber");
    var chassisNumber = sessionStorage.getItem("chassisNumber");



    var crashtype = sessionStorage.getItem("crashtype");

  

    
    var value;
    if(item_value === 'true'){
      value = true;
    } else {
      value = false;
    }
    
    const handleOpenParties = (e) => {
      setOpenParties(false);
    }

    // Function to handle button click and switch between windows
    const handleButtonClick = (windowName) => {
      setActiveWindow(windowName);
    };

        // Function to handle button click and switch between windows
    const handleButtonClick1 = (windowName) => {
      setActiveWindow1(windowName);
    };


    return (
        <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-filter1"
        sx={{ '& .MuiDialog-paper': { width: '90%' } }}
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title">
          {"Record"}
        </DialogTitle>
        <hr
        style={{
          background: 'lightgrey',
          color: 'lightgrey',
          borderColor: 'lightgrey',
          height: '1px',
        }}
      />
        <DialogContent>
      
          <DialogContentText id="alert-dialog-description">
        <button className={`record-button ${activeWindow === 'main' ? 'active' : ''}`} onClick={() => handleButtonClick('main')}>Incident Details</button>
        <button className={`record-button ${activeWindow === 'window2' ? 'active' : ''}`} onClick={() => handleButtonClick('window2')}>Notes</button>
        <button className={`record-button ${activeWindow === 'window3' ? 'active' : ''}`} onClick={() => handleButtonClick('window3')}>Crash Diagram</button>
        {!isHidden && ( <button className={`record-button ${activeWindow === 'window4' ? 'active' : ''}`} onClick={() => handleButtonClick('window4')}>Parties</button>)}
        <hr className="hr-line"/>

      <div className="dialog-container">
         <div className={`dialog-window ${activeWindow === 'main' ? 'active' : ''}`}>
        <RecordMap></RecordMap>
        <br></br>
        <p>Latitude: {lat}</p>
        <p>Longitude: {lng}</p>
        <p>Address: {address}</p>
        <p>Date and time: {fromDate} to {toDate}</p>
        <p>Weather: {weather}</p>
        <p>Light: {light}</p>
        <p>Severity: {severities}</p>
        <p>Main cause: {maincause}</p>
        <p>Collision type: {collision}</p>
        <p>Reporting Agency: {agency}</p>
        <p>Email of Encoder: {email}</p>
        <p>Description: {description}</p>
        <p>Location Approximate: {approximate}</p>
        </div>
     <div className={`dialog-window ${activeWindow === 'window2' ? 'active' : ''}`}>

     <p style={{marginTop: '10px',color:'black'}}>Notes:</p>
        <br></br>

        <p>{notes}</p>

      </div>

      <div className={`dialog-window ${activeWindow === 'window3' ? 'active' : ''}`}>
      
      <p style={{marginTop: '10px', color: 'black'}}>Crash Type:</p>

        <br></br>

        <p>{crashtype}</p>

        </div>
        {!isHidden && (

        <div className={`dialog-window ${activeWindow === 'window4' ? 'active' : ''}`}>

        {/* <button className='parties-button' onClick={handleOpenParties}>+</button> */}
        <br></br>
        <div>
        <button className={`record-button ${activeWindow1 === 'main' ? 'active' : ''}`} onClick={() => handleButtonClick1('main')}>Overview</button>
        <button className={`record-button ${activeWindow1 === 'window5' ? 'active' : ''}`} onClick={() => handleButtonClick1('window5')}>Adult</button>
        <button className={`record-button ${activeWindow1 === 'window6' ? 'active' : ''}`} onClick={() => handleButtonClick1('window6')}>Minors</button>
        <button className={`record-button ${activeWindow1 === 'window7' ? 'active' : ''}`} onClick={() => handleButtonClick1('window7')}>Photos</button>
        <hr className="hr-line"/>


        <div className={`dialog-window ${activeWindow1 === 'main' ? 'active' : ''}`}>

        <p style={{marginTop: '10px',color:'black'}}>Party Name:</p>
     

        <div className='vehicle-form'>
        <h2 style={{color: 'black', marginTop: '10px'}}>Vehicle</h2>
        <h4>A vehicle involved in the incident</h4>
        <p style={{marginTop: '10px', color: 'black'}}>Classification: {classification}</p>
        <p style={{marginTop: '10px', color: 'black'}}>Vehicle Type: {vehicleType}</p>
        <p style={{marginTop: '10px',color:'black'}}>Make: {make}</p>
        <p style={{marginTop: '10px',color:'black'}}>Plate number: {plateNumber}</p>
        <p style={{marginTop: '10px',color:'black'}}>Model: {model}</p>
        <p style={{marginTop: '10px', color: 'black'}}>Manuever: {maneuver}</p>
        <p style={{marginTop: '10px', color: 'black'}}>Damage: {damages}</p>
        <p style={{marginTop: '10px', color: 'black'}}>Defect: {defects}</p>
        <p style={{marginTop: '10px', color: 'black'}}>Loading: {loading}</p>
        <p style={{marginTop: '10px',color:'black'}}>Insurance Details: {insuranceDetails}</p>
        <p style={{marginTop: '10px',color:'black'}}>Engine number: {engineNumber}</p>
        <p style={{marginTop: '10px',color:'black'}}>Chasis number: {chassisNumber}</p>
      
        </div>
   
        </div>

        <div className={`dialog-window ${activeWindow1 === 'window5' ? 'active' : ''}`}>

        <h2 style={{color: 'black', marginTop: '10px'}}>Person</h2>
        <h4 style={{color: 'black'}}>A person involved in the incident</h4>
      
        <p style={{marginTop: '10px', color: 'black'}}>Involvement: {adult_involvement}</p>
        <p style={{marginTop: '10px',color:'black'}}>First Name: {adult_first_name}</p>
        <p style={{marginTop: '10px',color:'black'}}>Middle Name: {adult_middle_name}</p>
        <p style={{marginTop: '10px',color:'black'}}>Last Name: {adult_last_name}</p>
        <p style={{marginTop: '10px',color:'black'}}>Address: {adult_address}</p>
        <p style={{marginTop: '10px', color: 'black'}}>Gender: {adult_gender_name}</p>
        <p style={{marginTop: '10px',color:'black'}}>License Number: {adult_license_number}</p>
        <p style={{marginTop: '10px',color:'black'}}>Age: {adult_age}</p>
        <p style={{marginTop: '10px', color: 'black'}}>Driver Error: {adult_driverError}</p>
        <p style={{marginTop: '10px', color: 'black'}}>Alcohol Suspicion: {adult_alchoholSuspicion}</p>
        <p style={{marginTop: '10px', color: 'black'}}>Drug Suspicion: {adult_drugsSuspicion}</p>
        <p style={{marginTop: '10px', color: 'black'}}>Seat belt/helmet:{adult_seatbeltState}</p>
        <p style={{marginTop: '10px',color:'black'}}>Hospital: {adult_hospital}</p>

      
        </div>


        <div className={`dialog-window ${activeWindow1 === 'window6' ? 'active' : ''}`}>


        <h2 style={{color: 'black', marginTop: '10px'}}>Child</h2>
        <h4 style={{color: 'black'}}>A child involved in the incident</h4>
        
        <p style={{marginTop: '10px', color: 'black'}}>Grade Level: {minor_grade_level}</p>
        <p style={{marginTop: '10px', color: 'black'}}>Involvement: {minor_involvement}</p>
        <p style={{marginTop: '10px',color:'black'}}>First Name: {minor_first_name}</p>
        <p style={{marginTop: '10px',color:'black'}}>Middle Name: {minor_middle_name}</p>
        <p style={{marginTop: '10px',color:'black'}}>Last Name: {minor_last_name}</p>
        <p style={{marginTop: '10px',color:'black'}}>Address: {minor_address}</p>
        <p style={{marginTop: '10px', color: 'black'}}>Gender: {minor_gender_name}</p>
        <p style={{marginTop: '10px',color:'black'}}>License Number: {minor_license_number}</p>
        <p style={{marginTop: '10px',color:'black'}}>Age: {minor_age}</p>
        <p style={{marginTop: '10px', color: 'black'}}>Driver Error: {minor_driverError}</p>
        <p style={{marginTop: '10px', color: 'black'}}>Alcohol Suspicion: {minor_alchoholSuspicion}</p>
        <p style={{marginTop: '10px', color: 'black'}}>Drug Suspicion: {minor_drugsSuspicion}</p>
        <p style={{marginTop: '10px', color: 'black'}}>Seat belt/helmet:{minor_seatbeltState}</p>
        <p style={{marginTop: '10px',color:'black'}}>Hospital: {minor_hospital}</p>

      
        </div>
        </div>
        </div>
         )}

    </div>
            <br></br>
            <br></br>
        </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>

    )

}

export default ViewRecord;
