import React, { useEffect, useRef, useState } from "react";
import { menuItemsData } from "../MenuItemsData";
import MobileMenuItems from "./MobileMenuItems";
import { GiHamburgerMenu } from "react-icons/gi";
import { CiFilter } from "react-icons/ci";
import { BsFillPinMapFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import FilterDialog from './FilterDialog'
import DateFilter from './DateFilter'
import { IoClose } from "react-icons/io5";
import ReportDialog from './ReportDialog'
import { BsListNested } from "react-icons/bs";


const MobileNav = () => {

  // const depthLevel = 0;
  const [showMenu, setShowMenu] = useState(false);
  let ref = useRef();

  var item_value = sessionStorage.getItem("login_user");
  var selected_menu = sessionStorage.getItem("selected_menu");
  
  const navigate = useNavigate();
  const handleChange_privacy = (e) => {
    navigate('/privacy');

  }

  const handleChange_stph = (e) => {
    navigate('/data-review');
  }

  const depthLevel = 0;


  const [dialogIsOpen, setDialogIsOpen] = React.useState(false)
  const [filterC, setFilterC] = React.useState(false);
  
  const [openC, setOpenC] = React.useState(true)
  const [closeC, setCloseC] = React.useState(false);



  const closeDialog = () => {
    setDialogIsOpen(false);
    setFilterC(false);

  }

  const openDialog = () => {
    setDialogIsOpen(true);
    setFilterC(true);
  }

  const [dialogIsOpen2, setDialogIsOpen2] = React.useState(false)
  const openDialog2 = () => setDialogIsOpen2(true)
  const closeDialog2 = () => setDialogIsOpen2(false)


  var value;
  var value1;
  var value2;


  if(item_value === 'false'){
    value = false;
  } else {
    value = true;
  }

  if(selected_menu === 'List' || selected_menu === 'Reports' || selected_menu === 'Charts' || selected_menu === 'Privacy' || selected_menu === 'Data-review'){
    value1 = true;
  } else {
    value1 = false;
  }

  if(selected_menu === 'Reports'){
    value2 = false;
  } else {
    value2 = true;
  }

  const openDialog1 = (e) => {
    sessionStorage.setItem("record-button", "clicked");
    setFilterC(true);
    setCloseC(false);
    setOpenC(true);
  }


  const opemButton = (event) => {
        setFilterC(false);
        setShowMenu(true);
        setOpenC(false);
        setCloseC(true);
    }

    const closeButton = (event) => {
      setShowMenu(false);
      setCloseC(false);
      setOpenC(true);
    }


  return (
    <nav className="mobile-nav">
      <button
        className="mobile-nav__menu-button"
        type="button"
        onClick={opemButton}
        hidden={closeC}
        >
        <div>
        <GiHamburgerMenu hidden={closeC}/>
        </div>
        </button>


        <button
        className="mobile-nav__menu-button"
        type="button"
        onClick={closeButton}
        hidden={openC}
        >
        <div>
        <IoClose hidden={openC} />
        </div>
      </button>

      {showMenu && (
        <ul className="menus" ref={ref} hidden={filterC}>
          {menuItemsData.map((menu, index) => {
            return (
              <MobileMenuItems
                items={menu}
                key={index}
                depthLevel={depthLevel}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
              />
            );
          })}

        <button className="privacy-box"  onClick={handleChange_privacy}>
        <p style={{fontSize: "13px", marginTop: "5px"}}>Privacy</p>
        </button>
        <br></br>
        <br></br>
        <button className="stph-box" onClick={handleChange_stph} hidden={value? true : false}>
        <p style={{fontSize: "13px", marginLeft: '2px'}}>Data Review</p></button>
        <br></br>
        <br></br>

      
        <button className="filter-box" style={{marginLeft: '15px'}} onClick={openDialog}>
        <div style={{marginTop: '5px'}}>
          <CiFilter/>
          </div>
        </button>
        <DateFilter open={dialogIsOpen} onClose={closeDialog}></DateFilter>


        <button className="record-box" onClick={openDialog1} hidden={value? true : false}> 
        <div style={{marginTop: '5px'}}>
          <BsFillPinMapFill/>
          </div>
        </button>

   
        <button className="report-box" onClick={openDialog2} hidden={value2? true : false} > 
        <div style={{marginTop: '5px'}}>
          <BsListNested/>
        </div>
        </button>
        <ReportDialog open={dialogIsOpen2} onClose={closeDialog2}></ReportDialog>
    
        {/* <RecordDialog open={dialogIsOpen1} onClose={closeDialog1}></RecordDialog> */}
        

        </ul>
      )}
    </nav>
  );
};

export default MobileNav;
