import {gql} from '@apollo/client'

export const CREATE_POST_5 = gql`
mutation CreatePost ($description: String!, $encodeEmail: String!, $dateTime: DateTime!, $collisionType: CollisionTypeEnum, $weather: WeatherEnum!, 
    $light: LightEnum, $lat_convert: Float!, $lng_convert: Float!, $loc: String, $locationApproximate: Boolean!, $mainCause: MainCauseEnum, 
    $reportingAgency: AgencyEnum, $crashType:  CrashTypeEnum!,  $notes: String
) {
  incident_add(entity: {
          isVerified: false,
          incidentDetails: {
              collisionType: $collisionType,
              fromDateTime: $dateTime,
              toDateTime: $dateTime,
              description: $description,
              encoderEmail: $encodeEmail,
              light: $light,
              location: {
                  latitude: $lat_convert,
                  longitude: $lng_convert,
                  address: $loc
              },
              locationApproximate: $locationApproximate,
              mainCause: $mainCause,
              reportingAgency: $reportingAgency,
              severities: INJURY,
              weather: $weather
          },
          crashDiagram: {
            crashType: $crashType,
            movementCode: ""
          },
          notes: $notes,
          parties: []
      }
  ) {
      id
      incidentDetails {
        location {
            latitude
            longitude
          }
        fromDateTime
        toDateTime
        weather
        light
        severities
        mainCause
        collisionType
        reportingAgency
        encoderEmail
        description
        locationApproximate
      }
      notes
      crashDiagram {
        id
        crashType
        movementCode
      }
      isVerified
    }
  }
`;

