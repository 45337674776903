import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import RecordMap from '../screens/recordMap';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useMutation } from '@apollo/client';

import { CREATE_POST } from '../query_and_mutations/record_mutation';
import { CREATE_POST_2 } from '../query_and_mutations/record_mutation_2';
import { CREATE_POST_3 } from '../query_and_mutations/record_mutation_3';
import { CREATE_POST_4 } from '../query_and_mutations/record_mutation_4';
import { CREATE_POST_5 } from '../query_and_mutations/record_mutation_5';
import { Alert } from 'flowbite-react';

sessionStorage.setItem("Adult", "");
sessionStorage.setItem("Child", "");

console.log(sessionStorage.getItem("Adult"));
console.log(sessionStorage.getItem("Child"));

function RecordDialog(props) {

    const { open, onClose } = props
    const [activeWindow, setActiveWindow] = useState('main');
    const [activeWindow1, setActiveWindow1] = useState('main');
    const [peopleOpen, setpeopleOpen] = React.useState(true);
    const [photoOpen, setphotoOpen] = React.useState(true);
    const [childOpen, setChildOpen] = React.useState(true)
    const [openParties, setOpenParties] = React.useState(true)
    var item_value = sessionStorage.getItem("login_user");

    const Popup = ({ onClose }) => {
      return (
        <div className="popup">
          <div className="popup-inner">
            <h2>Incident report was successfully added!!</h2>
            <button className="yes-btn" onClick={() => {
            onClose()
            window.location.reload();
            }
            }>Close</button>
          </div>
        </div>
      );
    }

    var value;
    if(item_value === 'true'){
      value = true;
    } else {
      value = false;
    }
    

    var openAdult;
    var openChild;
    var mutationType;

    const handleOpenParties = (e) => {
      setOpenParties(false);
      mutationType = 'clicked_1';
      sessionStorage.setItem("mutation_state", "clicked_1");
      sessionStorage.setItem("openParties", "clicked");
    }
  
    const handleOpenPeople = (e) => {
      setpeopleOpen(false);
      openAdult = 'clicked';
      sessionStorage.setItem("Adult", "clicked");
      
      if (sessionStorage.getItem("Child") === "clicked") {
        mutationType = 'clicked_4';
        sessionStorage.setItem("mutation_state", "clicked_4");
        console.log(mutationType);
      } else {
        mutationType = 'clicked_2';
        sessionStorage.setItem("mutation_state", "clicked_2");
        console.log(mutationType);
      }
    }
  
    const handleOpenChild = (e) => {
      setChildOpen(false);
      openChild = 'clicked';
      sessionStorage.setItem("Child", "clicked");

      if (sessionStorage.getItem("Adult") === "clicked") {
        mutationType = 'clicked_4';
        sessionStorage.setItem("mutation_state", "clicked_4");
        console.log(mutationType);
      } else {
        mutationType = 'clicked_3';
        sessionStorage.setItem("mutation_state", "clicked_3");
        console.log(mutationType);
      }
    }


    const handleOpenPhoto = (e) => {
      setphotoOpen(false);
    }

    // Function to handle button click and switch between windows
    const handleButtonClick = (windowName) => {
      setActiveWindow(windowName);
    };

        // Function to handle button click and switch between windows
    const handleButtonClick1 = (windowName) => {
      setActiveWindow1(windowName);
    };

    var lat = sessionStorage.getItem("lat");
    var lng = sessionStorage.getItem("lng");
    var loc = sessionStorage.getItem("loc");

    const [value1, setValue] = React.useState(dayjs(new Date()));


    //Incident Details
    const [collisionType, setcollisionType] = useState('HEAD_ON');
    const [dateTime, setdateTime] = useState(dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"));
    const [description, setdescription] = useState('');
    const [encodeEmail, setencodeEmail] = useState('');
    const [weather, setweather] = useState('DAY');
    const [light, setlight] = useState('NIGHT');
    const [latitude_, setlatitude_] = useState('');
    const [longitude_, setlongitude_] = useState('');
  
    const [locationApproximate, setlocationApproximate] = useState(true);
    const [mainCause, setmainCause] = useState('HUMAN_ERROR');
    const [reportingAgency, setreportingAgency] = useState('PNP');
    const [severity, setseverity] = useState('');
    const [crashType, setcrashType] = useState('PEDESTRIAN_ON_FOOT');
    const [maimovementCodenCause, setmovementCode] = useState('');
    const [notes, setnotes] = useState('');


    
    const handlecollisionType = (e) => {
      setcollisionType(e.target.value === '' ? null : e.target.value);
    };


    const handleweather = (e) => {
      setweather(e.target.value === '' ? null : e.target.value);
    };

    const handleDateChange = (newDate) => {
      setValue(newDate);
      setdateTime(dayjs(newDate).format("YYYY-MM-DD HH:mm:ss"));
    };
    const handledescription = (e) => {
      setdescription(e.target.value === '' ? null : e.target.value);
    };

    const handleencodeEmail = (e) => {
      setencodeEmail(e.target.value === '' ? null : e.target.value);
    };

    const handlelightl = (e) => {
      setlight(e.target.value === '' ? null : e.target.value);
    };

    const handlelocationApproximate = (e) => {
      if(e.target.value === 'true'){
        setlocationApproximate(true);
      }

      if(e.target.value === 'false'){
        setlocationApproximate(false);
      }
    };

    const handlemainCause = (e) => {
      setmainCause(e.target.value === '' ? null : e.target.value);
    };

    const handlereportingAgency = (e) => {
      setreportingAgency(e.target.value === '' ? null : e.target.value);
    };

    const handleseverity = (e) => {
      setcrashType(e.target.value === '' ? null : e.target.value);
    };

    const handlecrashType = (e) => {
      setcrashType(e.target.value === '' ? null : e.target.value);
    };

    const handlemaimovementCodenCause = (e) => {
      setmovementCode(e.target.value === '' ? null : e.target.value);
    };

    const handlenotes = (e) => {
      setnotes(e.target.value === '' ? null : e.target.value);
    };
    
    //parties - adults
    const [address, setaddress] = useState('');
    const [age, setage] = useState('');
    const [alchoholSuspicion, setalchoholSuspicion] = useState(true);
    const [drugsSuspicion, setdrugsSuspicion] = useState('true');
    const [driverError, setdriverError] = useState('FATIGUED_OR_ASLEEP');
    const [firstName, setfirstName] = useState('');
    const [middleName, setmiddleName] = useState('');
    const [lastName, setlastName] = useState('');
    const [gender, setgender] = useState('MALE');
    const [helmentState, sethelmentState] = useState('WORN_CORRECTLY');
    const [hospital, sethospital] = useState('');
    const [injury, setinjury] = useState('FATAL');
    const [involvement, setinvolvement] = useState('PEDESTRIAN');
    const [licensenumber, setlicensenumber] = useState('');
    const [seatbeltState, setseatbeltState] = useState('WORN_CORRECTLY');

    const handleAdultAddress = (e) => {
      setaddress(e.target.value === '' ? null : e.target.value);
    };

    const handleAdultAge = (e) => {
      setage(parseFloat(e.target.value));
    };

    const handleAlcoholSuspicion = (e) => {
      if(e.target.value === 'true'){
        setalchoholSuspicion(true);
      }

      if(e.target.value === 'false'){
        setalchoholSuspicion(false);
      }
    };

    const handleDrugSuspicion = (e) => {
      if(e.target.value === 'true'){
        setdrugsSuspicion(true);
      }

      if(e.target.value === 'false'){
        setdrugsSuspicion(false);
      }
    };

    const handleDriverError = (e) => {
      setdriverError(e.target.value === '' ? null : e.target.value);
    };

    const handleAdultFname = (e) => {
      setfirstName(e.target.value === '' ? null : e.target.value);
    };

    const handleAdultMname = (e) => {
      setmiddleName(e.target.value === '' ? null : e.target.value);
    };

    const handleAdultLname = (e) => {
      setlastName(e.target.value === '' ? null : e.target.value);
    };

    const handleAdultGender = (e) => {
      setgender(e.target.value === '' ? null : e.target.value);
    };

    const handleHelmetState = (e) => {
      sethelmentState(e.target.value === '' ? null : e.target.value);
    };

    const handleHospital = (e) => {
      sethospital(e.target.value === '' ? null : e.target.value);
    };

    const handleInjury = (e) => {
      setinjury(e.target.value === '' ? null : e.target.value);
    };

    const handleInvolvement = (e) => {
      setinvolvement(e.target.value === '' ? null : e.target.value);
    };

    const handleLicenseNumber = (e) => {
      setlicensenumber(e.target.value === '' ? null : e.target.value);
    };

    const handleSeatbeltState = (e) => {
      setseatbeltState(e.target.value === '' ? null : e.target.value);
    };


    //minors
    const [m_grade, setminorgrade] = useState('PRESCHOOL');
    const [m_address, setminoraddress] = useState('');
    const [m_age, setminorage] = useState('');
    const [m_alchoholSuspicion, setminoralchoholSuspicion] = useState('true');
    const [m_drugsSuspicion, setminordrugsSuspicion] = useState('true');
    const [m_driverError, setminordriverError] = useState('FATIGUED_OR_ASLEEP');
    const [m_firstName, setminorfirstName] = useState('');
    const [m_middleName, setminormiddleName] = useState('');
    const [m_lastName, setminorlastName] = useState('');
    const [m_gender, setminorgender] = useState('MALE');
    const [m_helmentState, setminorhelmentState] = useState('WORN_CORRECTLY');
    const [m_hospital, setminorhospital] = useState('');
    const [m_injury, setminorinjury] = useState('FATAL');
    const [m_involvement, setminorinvolvement] = useState('PEDESTRIAN');
    const [m_licensenumber, setminorlicensenumber] = useState('');
    const [m_seatbeltState, setminorseatbeltState] = useState('WORN_CORRECTLY');


    const handleMinorgrade = (e) => {
      setminorgrade(e.target.value);
    };

    const handleMinorAddress = (e) => {
      setminoraddress(e.target.value);
    };

    
    const handleMinorAge = (e) => {
      setminorage(parseFloat(e.target.value));
    };


    const handleMinorAlcoholSuspicion = (e) => {

      if(e.target.value === 'true'){
        setminoralchoholSuspicion(true);
      }

      if(e.target.value === 'false'){
        setminoralchoholSuspicion(false);
      }
  
    };

    const handleMinorDrugSuspicion = (e) => {
      if(e.target.value === 'true'){
        setminordrugsSuspicion(true);
      }
      if(e.target.value === 'false'){
        setminordrugsSuspicion(false);
      }
    };

    const handleMinorDriverError = (e) => {
      setminordriverError(e.target.value);
    };

    const handleMinorFirstName = (e) => {
      setminorfirstName(e.target.value);
    };

    const handleMinorMiddleName = (e) => {
      setminormiddleName(e.target.value);
    };

    const handleMinorLastName = (e) => {
      setminorlastName(e.target.value);
    };

    const handleMinorGender = (e) => {
      setminorgender(e.target.value);
    };

    const handleMinorHelmetState = (e) => {
      setminorhelmentState(e.target.value);
    };

    const handleMinorHospital = (e) => {
      setminorhospital(e.target.value);
    };

    const handleMinorInjury = (e) => {
      setminorinjury(e.target.value);
    };

    const handleMinorInvolvement = (e) => {
      setminorinvolvement(e.target.value);
    };

    const handleMinorLicenseNumber = (e) => {
      setminorlicensenumber(e.target.value);
    };

    const handleMinorSeatbeltState = (e) => {
      setminorseatbeltState(e.target.value);
    };

    //PartyName
    const [partyName, setPartyName] = useState('');

    const handlePartyName = (e) => {
      setPartyName(e.target.value);
    };

    //vehicle
    const [chassisNumber, setchassisNumber] = useState('');
    const [classification, setclassification] = useState('PRIVATE');
    const [damages, setdamages] = useState('NONE');
    const [defect, setdefect] = useState('NONE');
    const [engineNumber, setengineNumber] = useState('');
    const [insuranceDetails, setinsuranceDetails] = useState('');
    const [loading, setloading] = useState('LEGAL');
    const [make, setmake] = useState('');
    const [maneuver, setmaneuver] = useState('LEFT_TURN');
    const [model, setmodel] = useState('');
    const [plateNumber, setplateNumber] = useState('');
    const [vehicleType, setvehicleType] = useState('CAR');

    const handleChassisNum = (e) => {
      setchassisNumber(e.target.value);
    };

    const handleClassification = (e) => {
      setclassification(e.target.value);
    };

    const handleDamages = (e) => {
      setdamages(e.target.value);
    };

    const handleDefect = (e) => {
      setdefect(e.target.value);
    };

    const handleEngineNum = (e) => {
      setengineNumber(e.target.value);
    };

    const handleInsuranceDetails = (e) => {
      setinsuranceDetails(e.target.value);
    };

    const handleLoading = (e) => {
      setloading(e.target.value);
    };

    const handleMake = (e) => {
      setmake(e.target.value);
    };

    const handleManeuver = (e) => {
      setmaneuver(e.target.value);
    };

    const handleModel = (e) => {
      setmodel(e.target.value);
    };

    const handlePlateNumber = (e) => {
      setplateNumber(e.target.value);
    };

    const handleVehicleType = (e) => {
      setvehicleType(e.target.value);
    };
 
    const [createPost, { data, error }] = useMutation(
      sessionStorage.getItem("mutation_state") === 'clicked_1' ? CREATE_POST_2 :
      sessionStorage.getItem("mutation_state") === 'clicked_2' ? CREATE_POST_3 :
      sessionStorage.getItem("mutation_state") === 'clicked_3' ? CREATE_POST_4 :
      sessionStorage.getItem("mutation_state") === 'clicked_4' ? CREATE_POST :
      CREATE_POST_5,
      {
        onCompleted: (data) => {
          if (data) {
            setIsPopupOpen(!isPopupOpen);
            console.log(sessionStorage.getItem("mutation_state"));
            mutationType = '';
            // onClose();
            console.log(data);
          }
        },
        onError: (error) => {
          console.error("Mutation error:", error);
          alert("An error occurred while submitting the form. Please try again.");
          // window.location.reload();
        },
      }
    );

    const [isPopupOpen, setIsPopupOpen] = React.useState(false);

    const togglePopup = () => {
      setIsPopupOpen(!isPopupOpen);
    };
  
    const handleSubmit = () => {

        console.log(sessionStorage.getItem("mutation_state"));

      
        var lat_convert = parseFloat(lat);
        var lng_convert = parseFloat(lng);
  
        var party = 1.0;
        var partyIndex = parseFloat(party)
  

      // Execute the mutation with variables
      createPost({ variables: {description, encodeEmail, collisionType, dateTime, weather, light, lat_convert, lng_convert, loc, locationApproximate, mainCause, 
        reportingAgency, crashType, notes, chassisNumber, classification, damages, defect,
        engineNumber, insuranceDetails, loading, make, maneuver, model, plateNumber, vehicleType, partyName, partyIndex,
        address, age, alchoholSuspicion, drugsSuspicion, driverError, firstName, middleName, lastName, gender, helmentState, hospital,
        injury, involvement, licensenumber, seatbeltState, m_grade, m_address, m_age, m_alchoholSuspicion, m_drugsSuspicion, m_driverError,
        m_firstName, m_middleName, m_lastName, m_gender, m_helmentState, m_hospital, m_injury, m_involvement, m_licensenumber, m_seatbeltState
        } });

      // Clear the form fields after submission
      setcollisionType('');
      setdateTime('');
      setencodeEmail('');
      setweather('');
      setlight('');
      setlatitude_('');
      setlongitude_('');
      setlocationApproximate('');
      setmainCause('');
      setreportingAgency('');
      setseverity('');
      setcrashType('');
      setmovementCode('');
      setnotes('');

      setchassisNumber('');
      setclassification('');
      setdamages('');
      setdefect('');
      setengineNumber('');
      setinsuranceDetails('');
      setloading('');
      setmake('');
      setmaneuver('');
      setmodel('');
      setplateNumber('');
      setvehicleType('');
      setPartyName('');

      setaddress('');
      setage('');
      setalchoholSuspicion('');
      setdrugsSuspicion('');
      setdriverError('');
      setfirstName('');
      setmiddleName('');
      setlastName('');
      setgender('');
      sethelmentState('');
      sethospital('');
      setinjury('');
      setinvolvement('');
      setlicensenumber('');
      setseatbeltState('');

      setminorgrade('');
      setminoraddress('');
      setminorage('');
      setminoralchoholSuspicion('');
      setminordrugsSuspicion('');
      setminordriverError('');
      setminorfirstName('');
      setminormiddleName('');
      setminorlastName('');
      setminorgender('');
      setminorhelmentState('');
      setminorhospital('');
      setminorinjury('');
      setminorinvolvement('');
      setminorlicensenumber('');
      setminorseatbeltState('');

      mutationType = '';

    };
  

    return (
        <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-filter1"
        sx={{ '& .MuiDialog-paper': { width: '90%' } }}
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title">
          {"Record"}
        </DialogTitle>
        <hr
        style={{
          background: 'lightgrey',
          color: 'lightgrey',
          borderColor: 'lightgrey',
          height: '1px',
        }}
      />
        {/* hidden={value? true : false} hide in google log in people tab
        hidden={value? true : false} hide in google log in child tab */}
        <DialogContent>
      
        <DialogContentText id="alert-dialog-description">
        <button className={`record-button ${activeWindow === 'main' ? 'active' : ''}`} onClick={() => handleButtonClick('main')}>Incident Details</button>
        <button className={`record-button ${activeWindow === 'window2' ? 'active' : ''}`} onClick={() => handleButtonClick('window2')}>Notes</button>
        <button className={`record-button ${activeWindow === 'window3' ? 'active' : ''}`} onClick={() => handleButtonClick('window3')}>Crash Diagram</button>
        <button className={`record-button ${activeWindow === 'window4' ? 'active' : ''}`} onClick={() => handleButtonClick('window4')}>Parties</button>
        <hr className="hr-line"/>

      <div className="dialog-container">
         <div className={`dialog-window ${activeWindow === 'main' ? 'active' : ''}`}>
        <RecordMap></RecordMap>
  
        <br></br>
        <p style={{color:'black'}}>Latitude:</p>
        <input className='input-record' value={lat}></input>
        <hr className="hr-line-2"/>
        <p style={{color:'black'}}>Longitude:</p>
        <input className='input-record' value={lng}></input>
        <hr className="hr-line-2"/>
        <p style={{color:'black'}}>Location:</p>
        <input className='input-record' value={loc}></input>
        <hr className="hr-line-2"/>
  
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
        <DateTimePicker
          className='input-record1'
          label="Date and Time"
          value={value1}
          onChange={handleDateChange}
        />
        </DemoContainer>
        </LocalizationProvider>

        {/* <input className='input-record-date'></input> : &nbsp;
        <input className='input-record-date'></input> */}
        <hr className="hr-line-2"/>
        <p style={{color:'black'}}>Weather:</p>
        <select className='input-record' onChange={handleweather}>
        <option value="DAY">Day</option>
        <option value="CLEAR_DAY">Clear Day</option>
        <option value="CLEAR_NIGHT">Clear Night</option>
        <option value="CLOUDY">Cloudy</option>
        <option value="FOG">Fog</option>
        <option value="HAIL">Hail</option>
        <option value="PARTIALLY_CLOUDY_DAY">Partially Cloudy Day</option>
        <option value="PARTIALLY_CLOUDY_NIGHT">Partially Cloudy Night</option>
        <option value="RAIN">Rain</option>
        <option value="THUNDERSTORM">Thunderstorm</option>
        <option value="WIND">Wind</option>
        <option value="OVERCAST_CLOUDS">Overcast Clouds</option>
        <option value="SCATTERED_CLOUDS">Scattered Clouds</option>
        <option value="FEW_CLOUDS">Few Clouds</option>
        <option value="BROKEN_CLOUDS">Broken Clouds</option>
        <option value="MODERATE_RAIN">Moderate Rain</option>
        <option value="LIGHT_RAIN">Light Rain</option>
        <option value="TORNADO">Tornado</option>
        </select>
        <hr className="hr-line-2"/>

        <p style={{color:'black'}}>Light:</p>
        <select className='input-record' onChange={handlelightl}>
        <option value="NIGHT">Night</option>
        <option value="DUSK">Dusk</option>
        <option value="DAY">Day</option>
        <option value="DAWN">Dawn</option>
        </select>
       
        <p style={{marginTop: '15px', color: 'black'}}>Severity:</p>
        <input className='input-record-serverity' type="checkbox" value="PROPERTY_DAMAGE" style={{color: 'black'}}/> Property Damage
        <input className='input-record-serverity' type="checkbox" value="INJURY" style={{marginLeft: '10px', color: 'black'}}/> Injury
        <input className='input-record-serverity' type="checkbox" value="FATAL" style={{marginLeft: '10px', color: 'black'}}/> Fatal

        <p style={{marginTop: '10px', color: 'black'}}>Main Cause:</p>
        <select className='input-record' onChange={handlemainCause}>
        <option value="HUMAN_ERROR">HUMAN_ERROR</option>
        <option value="VEHICLE_DEFECT">VEHICLE_DEFECT</option>
        <option value="ROAD_DEFECT">ROAD_DEFECT</option>
        <option value="OTHER">OTHER</option>
        </select>

        <p style={{marginTop: '10px', color: 'black'}}>Collision Type:</p>
        <select className='input-record' onChange={handlecollisionType}>
        <option value="HEAD_ON">HEAD_ON</option>
        <option value="REAR_END">REAR_END</option>
        <option value="RIGHT_ANGLE">RIGHT_ANGLE</option>
        <option value="OTHER_ANGLE">OTHER_ANGLE</option>
        <option value="SIDE_SWIPE">SIDE_SWIPE</option>
        <option value="OVERTURNED_VEHICLE">OVERTURNED_VEHICLE</option>
        <option value="HIT_OBJECT_ON_ROAD">HIT_OBJECT_ON_ROAD</option>
        <option value="HIT_OBJECT_OFF_ROAD">HIT_OBJECT_OFF_ROAD</option>
        <option value="HIT_PARKED_VEHICLE">HIT_PARKED_VEHICLE</option>
        <option value="HIT_PEDESTRIAN">HIT_PEDESTRIAN </option>
        <option value="HIT_ANIMAL">HIT_ANIMAL</option>
        <option value="OTHER">OTHER</option>
        </select>

        <p style={{marginTop: '10px', color: 'black'}}>Reporting Agency:</p>
        <select className='input-record' onChange={handlereportingAgency}>
        <option value="PNP">PNP</option>
        <option value="LOCAL_TRAFFIC_UNIT">Local Traffic Unit</option>
        <option value="BGCEA">BGCEA</option>
        <option value="CCTO">CCTO</option>
        <option value="MMDA_METROBASE">MMDA Metrobase</option>
        <option value="MMDA_ROAD_SAFETY_UNIT">MMDA Road Safety Unit</option>
        <option value="MUNTINLUPA_TRAFFIC_MANAGEMENT_BUREAU">Muntinlupa Traffic Management Bureau</option>
        <option value="ZAMBOANGA_ADMIN_OFFICE">Zamboanga Admin Office</option>
        <option value="OTHER">Other</option>
        </select>

        <p style={{marginTop: '10px', color: 'black'}}>Email of Encoder:</p>
        <input className='input-record' onChange={handleencodeEmail}></input>

        <p style={{marginTop: '10px', color: 'black'}}>Description:</p>
        <input className='input-record' onChange={handledescription}></input>

        <p style={{marginTop: '10px', color: 'black'}}>Location Approximate:</p>
        <select className='input-record' onChange={handlelocationApproximate}>
        <option value="true">Yes</option>
        <option value="false">No</option>
        </select>
       

    </div>
     <div className={`dialog-window ${activeWindow === 'window2' ? 'active' : ''}`}>

     <p style={{marginTop: '10px',color:'black'}}>Notes:</p>
        <input className='input-record' onChange={handlenotes}></input>
        
      </div>

      <div className={`dialog-window ${activeWindow === 'window3' ? 'active' : ''}`}>
      
      <p style={{marginTop: '10px', color: 'black'}}>Crash Type:</p>
        <select className="input-record" onChange={handlecrashType}>
        <option value="PEDESTRIAN_ON_FOOT">Pedestrian on foot</option>
        <option value="VEHICLE_FROM_ADJACENT_DIRECTION_INTERSECTION_ONLY">Vehicle from adjacent direction (intersection only)</option>
        <option value="VEHICLE_FROM_ADJACENT_DIRECTION">Vehicle from opposing direction</option>
        <option value="VEHICLE_FROM_SAME_DIRECTION">Vehicle from same direction</option>
        <option value="MANEUVERING">Manuevering</option>
        <option value="OVERTAKING">Overtaking</option>
        <option value="ON_PATH">On path</option>
        <option value="OFF_PATH_STRAIGHT">On path straight</option>
        <option value="OFF_PATH_CURVE">Off path curve</option>
        <option value="PASSENGER_AND_MISCELLANEOUS">Passenger and miscellaneous</option>
        </select>

        <p style={{marginTop: '10px',color:'black'}}>Movement Code:</p>
        <input className='input-record' onChange={handlemaimovementCodenCause}></input>

        <p style={{marginTop: '10px',color:'black'}}>Image:</p>
       
        </div>

        <div className={`dialog-window ${activeWindow === 'window4' ? 'active' : ''}`}>

        <button className='parties-button' onClick={handleOpenParties}>+</button>
        <br></br>
        <div hidden={openParties}>
        <button className={`record-button ${activeWindow1 === 'main' ? 'active' : ''}`} onClick={() => handleButtonClick1('main')}>Overview</button>
        <button className={`record-button ${activeWindow1 === 'window5' ? 'active' : ''}`} onClick={() => handleButtonClick1('window5')}>Adult</button>
        <button className={`record-button ${activeWindow1 === 'window6' ? 'active' : ''}`} onClick={() => handleButtonClick1('window6')}>Minors</button>
        <button className={`record-button ${activeWindow1 === 'window7' ? 'active' : ''}`} onClick={() => handleButtonClick1('window7')}>Photos</button>
        <hr className="hr-line"/>

        <div className={`dialog-window ${activeWindow1 === 'main' ? 'active' : ''}`}>

        <p style={{marginTop: '10px',color:'black'}}>Party Name:</p>
        <input className='input-record' onChange={handlePartyName}></input>

        <div className='vehicle-form'>
        <h2 style={{color: 'black', marginTop: '10px'}}>Vehicle</h2>
        <h4>A vehicle involved in the incident</h4>

        <p style={{marginTop: '10px', color: 'black'}}>Classification:</p>
        <select className="input-record" onChange={handleClassification}>
        <option value="PRIVATE">Private</option>
        <option value="GOVERNMENT">Government</option>
        <option value="PUBLIC_OR_FOR_HIRE">Public / For-hire</option>
        <option value="DIPLOMAT">Diplomat</option>
        </select>

        <p style={{marginTop: '10px', color: 'black'}}>Vehicle Type:</p>
        <select className="input-record" onChange={handleVehicleType}>
        <option value="CAR">Car</option>
        <option value="VAN">Van</option>
        <option value="SUV">SUV</option>
        <option value="BUS">Bus</option>
        <option value="JEEPNEY">Jeepney</option>
        <option value="TAXI_METERED">Taxi (metered)</option>
        <option value="TRUCK_PICK_UP">Truck (Pick-Up)</option>
        <option value="TRUCK_RIGID">Truck (Rigid)</option>
        <option value="TRUCK_ARTICULATED">Truck (Articulated)</option>
        <option value="TRUCK_FIRE">Truck (Fire)</option>
        <option value="TRUCK_UNKNOWN">Truck (Unknown)</option>
        <option value="TRUCK">Truck</option>
        <option value="AMBULANCE">Ambulance</option>
        <option value="ARMORED_CAR">Armored Car</option>
        <option value="HEAVY_EQUIPMENT">Heavy Equipment</option>
        <option value="MOTORCYCLE">Motorcycle</option>
        <option value="TRICYCLE">Tricycle</option>
        <option value="BICYCLE">Bicycle</option>
        <option value="PEDICAB">Pedicab</option>
        <option value="PEDESTRIAN">Pedestrian</option>
        <option value="PUSH_CART">Push-Cart</option>
        <option value="TARTANILLA">Horse-Driven Carriage (Tartanilla)</option>
        <option value="ANIMAL">Animal</option>
        <option value="WATER_VESSEL">Water Vessel</option>
        <option value="ELECTRIC_BIKE">Electric Bike</option>
        <option value="OTHERS">Others</option>
        <option value="HABAL_HABAL">Habal-habal</option>
        </select>

        <p style={{marginTop: '10px',color:'black'}}>Make:</p>
        <input className='input-record' onChange={handleMake}></input>
        
        <p style={{marginTop: '10px',color:'black'}}>Plate number:</p>
        <input className='input-record' onChange={handlePlateNumber}></input>
       

        <p style={{marginTop: '10px',color:'black'}}>Model:</p>
        <input className='input-record' onChange={handleModel}></input>
       

        <p style={{marginTop: '10px', color: 'black'}}>Manuever:</p>
        <select className="input-record" onChange={handleManeuver}>
        <option value="LEFT_TURN">Left turn</option>
        <option value="RIGHT_TURN">Right turn</option>
        <option value="U_TURN">U turn</option>
        <option value="CROSS_TRAFFIC">Cross traffic</option>
        <option value="MERGING">Merging</option>
        <option value="DIVERGING">Diverging</option>
        <option value="OVERTAKING">Overtaking</option>
        <option value="GOING_AHEAD">Going ahead</option>
        <option value="REVERSING">Reversing</option>
        <option value="SUDDEN_START">Sudden start</option>
        <option value="SUDDEN_STOP">Sudden stop</option>
        <option value="PARKED_OFF_ROAD">Parked off road</option>
        <option value="PARKED_ON_ROAD">Parked on road</option>
        </select>

        <p style={{marginTop: '10px', color: 'black'}}>Damage:</p>
        <select className="input-record" onChange={handleDamages}>
        <option value="NONE">None</option>
        <option value="FRONT">Front</option>
        <option value="REAR">Rear</option>
        <option value="RIGHT">Right</option>
        <option value="LEFT">Left</option>
        <option value="ROOF">Roof</option>
        <option value="MULTIPLE">Multiple</option>
        </select>

        
        <p style={{marginTop: '10px', color: 'black'}}>Defect:</p>
        <select className="input-record" onChange={handleDefect}>
        <option value="NONE">None</option>
        <option value="LIGHTS">Lights</option>
        <option value="BRAKES">Brakes</option>
        <option value="STEERING">Steering</option>
        <option value="TIRES">Tires</option>
        <option value="MULTIPLE">Multiple</option>
        </select>

        <p style={{marginTop: '10px', color: 'black'}}>Loading:</p>
        <select className="input-record" onChange={handleLoading}>
        <option value="LEGAL">Legal</option>
        <option value="OVERLOADED">Overloaded</option>
        <option value="UNSAFE_LOAD">Unsafe Load</option>
        </select>

        <p style={{marginTop: '10px',color:'black'}}>Insurance Details:</p>
        <input className='input-record' onChange={handleInsuranceDetails}></input>
        
        <p style={{marginTop: '10px',color:'black'}}>Engine number:</p>
        <input className='input-record' onChange={handleEngineNum}></input>
       

        <p style={{marginTop: '10px',color:'black'}}>Chasis number:</p>
        <input className='input-record' onChange={handleChassisNum}></input>
        </div>
   
        </div>

        <div className={`dialog-window ${activeWindow1 === 'window5' ? 'active' : ''}`}>

        <button className='people-button' onClick={handleOpenPeople} >+</button>
        <div className='people-form' hidden={peopleOpen}>
        <h2 style={{color: 'black', marginTop: '10px'}}>Person</h2>
        <h4 style={{color: 'black'}}>A person involved in the incident</h4>
        
        <p style={{marginTop: '10px', color: 'black'}}>Involvement:</p>
        <select className="input-record" onChange={handleInvolvement}>
        <option value="PEDESTRIAN">Pedestrian</option>
        <option value="WITNESS">Witness</option>
        <option value="PASSENGER">Passenger</option>
        <option value="DRIVER">Driver</option>
        </select>

        <p style={{marginTop: '10px',color:'black'}}>First Name:</p>
        <input className='input-record' onChange={handleAdultFname}></input>

        <p style={{marginTop: '10px',color:'black'}}>Middle Name:</p>
        <input className='input-record' onChange={handleAdultMname} ></input>

        <p style={{marginTop: '10px',color:'black'}}>Last Name:</p>
        <input className='input-record' onChange={handleAdultLname}></input>

        <p style={{marginTop: '10px',color:'black'}}>Address:</p>
        <input className='input-record' onChange={handleAdultAddress}></input>

        <p style={{marginTop: '10px', color: 'black'}}>Gender:</p>
        <select className="input-record" onChange={handleAdultGender}>
        <option value="MALE">Male</option>
        <option value="FEMALE">Female</option>
        <option value="OTHER">Other</option>
        </select>

        <p style={{marginTop: '10px',color:'black'}}>License Number:</p>
        <input className='input-record' onChange={handleLicenseNumber}></input>

        <p style={{marginTop: '10px',color:'black'}}>Age:</p>
        <input className='input-record' type='number' onChange={handleAdultAge}></input>

        <p style={{marginTop: '10px', color: 'black'}}>Driver Error:</p>
        <select className="input-record" onChange={handleDriverError}>
        <option value="FATIGUED_OR_ASLEEP">Fatigue/asleep</option>
        <option value="INATTENTIVE">Inattentive</option>
        <option value="TOO_FAST">Too Fast</option>
        <option value="TOO_CLOSE">Too Close</option>
        <option value="NO_SIGNAL">No Signal</option>
        <option value="BAD_OVERTAKING">Bad overtaking</option>
        <option value="BAD_TURNING">Bad turning</option>
        <option value="USING_CELLPHONE">Using cell phone</option>
        </select>

        <p style={{marginTop: '10px', color: 'black'}}>Injury:</p>
        <select className="input-record" onChange={handleInjury}>
        <option value="FATAL">Fatal</option>
        <option value="SERIOUS">Serious</option>
        <option value="MINOR">Minor</option>
        <option value="NOT_INJURED">Not Injured</option>
        </select>


        <p style={{marginTop: '10px', color: 'black'}}>Alcohol Suspicion:</p>
        <select className='input-record' onChange={handleAlcoholSuspicion}>
        <option value="true">Yes</option>
        <option value="false">No</option>
        </select>

        <p style={{marginTop: '10px', color: 'black'}}>Drug Suspicion:</p>
        <select className='input-record' onChange={handleDrugSuspicion}>
        <option value="true">Yes</option>
        <option value="false">No</option>
        </select>

        <p style={{marginTop: '10px', color: 'black'}}>Seat belt/helmet:</p>
        <select className="input-record" onChange={handleSeatbeltState}>
        <option value="WORN_CORRECTLY">Seat belt/helmet worn</option>
        <option value="NOT_WORN">Not worn</option>
        <option value="NOT_WORN_CORRECTLY">Not worn correctly</option>
        </select>

        <p style={{marginTop: '10px',color:'black'}}>Hospital:</p>
        <input className='input-record' onChange={handleHospital}></input>
        </div>


        </div>

        <div className={`dialog-window ${activeWindow1 === 'window6' ? 'active' : ''}`}>
        <button className='child-button' onClick={handleOpenChild} >+</button>
        <div className='child-form' hidden={childOpen}>
        <p style={{marginTop: '10px', color: 'black'}}>Grade level:</p>
        <select className="input-record" onChange={handleMinorgrade}>
        <option value="PRESCHOOL">Preschool</option>
        <option value="ELEMENTARY">Elementary</option>
        <option value="HIGH_SCHOOL">High School</option>
        </select>

        <p style={{marginTop: '10px', color: 'black'}}>Involvement:</p>
        <select className="input-record" onChange={handleMinorInvolvement}>
        <option value="PEDESTRIAN">Pedestrian</option>
        <option value="WITNESS">Witness</option>
        <option value="PASSENGER">Passenger</option>
        <option value="DRIVER">Driver</option>
        </select>

        <p style={{marginTop: '10px',color:'black'}}>First Name:</p>
        <input className='input-record' onChange={handleMinorFirstName}></input>

        <p style={{marginTop: '10px',color:'black'}}>Middle Name:</p>
        <input className='input-record' onChange={handleMinorMiddleName}></input>

        <p style={{marginTop: '10px',color:'black'}}>Last Name:</p>
        <input className='input-record' onChange={handleMinorLastName}></input>

        <p style={{marginTop: '10px',color:'black'}}>Address:</p>
        <input className='input-record' onChange={handleMinorAddress}></input>

        <p style={{marginTop: '10px', color: 'black'}}>Gender:</p>
        <select className="input-record" onChange={handleMinorGender}>
        <option value="MALE">Male</option>
        <option value="FEMALE">Female</option>
        <option value="OTHER">Other</option>
        </select>

        <p style={{marginTop: '10px',color:'black'}}>Age:</p>
        <input className='input-record' onChange={handleMinorAge}></input>

        <p style={{marginTop: '10px',color:'black'}}>License Number:</p>
        <input className='input-record' onChange={handleMinorLicenseNumber}></input>

        <p style={{marginTop: '10px', color: 'black'}}>Driver Error:</p>
        <select className="input-record" onChange={handleMinorDriverError}>
        <option value="FATIGUED_OR_ASLEEP">Fatigue/asleep</option>
        <option value="INATTENTIVE">Inattentive</option>
        <option value="TOO_FAST">Too Fast</option>
        <option value="TOO_CLOSE">Too Close</option>
        <option value="NO_SIGNAL">No Signal</option>
        <option value="BAD_OVERTAKING">Bad overtaking</option>
        <option value="BAD_TURNING">Bad turning</option>
        <option value="USING_CELLPHONE">Using cell phone</option>
        </select>

        <p style={{marginTop: '10px', color: 'black'}}>Injury:</p>
        <select className="input-record" onChange={handleMinorInjury}>
        <option value="FATAL">Fatal</option>
        <option value="SERIOUS">Serious</option>
        <option value="MINOR">Minor</option>
        <option value="NOT_INJURED">Not Injured</option>
        </select>

        <p style={{marginTop: '10px', color: 'black'}}>Alcohol Suspicion:</p>
        <select className='input-record' onChange={handleMinorAlcoholSuspicion}>
        <option value="true">Yes</option>
        <option value="false">No</option>
        </select>

        <p style={{marginTop: '10px', color: 'black'}}>Drug Suspicion:</p>
        <select className='input-record' onChange={handleMinorDrugSuspicion}>
        <option value="true">Yes</option>
        <option value="false">No</option>
        </select>

        <p style={{marginTop: '10px', color: 'black'}}>Seat belt/helmet:</p>
        <select className="input-record" onChange={handleMinorSeatbeltState}>
        <option value="WORN_CORRECTLY">Seat belt/helmet worn</option>
        <option value="NOT_WORN">Not worn</option>
        <option value="NOT_WORN_CORRECTLY">Not worn correctly</option>
        </select>

        <p style={{marginTop: '10px',color:'black'}}>Hospital:</p>
        <input className='input-record' onChange={handleMinorHospital}></input>
        </div>


        </div>


        <div className={`dialog-window ${activeWindow1 === 'window7' ? 'active' : ''}`}>

        <button className='photos-button' onClick={handleOpenPhoto}>+</button>
        <div className='photo-form' hidden={photoOpen}> 
        <h2 style={{color: 'black', marginTop: '10px'}}>Photo</h2>
        <h4 style={{marginLeft: '5px'}}>Pictures of the incident</h4>


        <p style={{marginTop: '10px', color: 'black'}}>Picture: Picture</p>
        <input className="input-image" type='file'></input>
        <p style={{marginTop: '10px',color:'black'}}>Description:</p>
        <input className='input-record'></input>
        </div>

        </div>
        </div>




        </div>


    </div>
            <br></br>
            <br></br>
        </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button>Delete</Button>
          <Button autoFocus onClick={handleSubmit}>Save</Button>
          {isPopupOpen && <Popup onClose={togglePopup} />}
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>

    )

}

export default RecordDialog;



